import { useGetEndUserDemandAreasQuery, useGetPublicDemandAreasQuery } from '../../store/api/endUser';
import { useAppSelector } from '../../store/hooks';
import { END_USER_HARDCODED_DOMAIN, END_USER_HARDCODED_MODEL_URL } from '../../utils/constants';
import { isProductionBuild } from '../../utils/helpers';

// const modelUrlForDev = 'https://mein-zuhause-energie-schwaben.preprod.hausversorger.de';
// const tenantIdForDev = '11005';

// const modelUrlForDev = 'https://stadtwerk-tauberfranken.preprod.hausversorger.de';
// const tenantIdForDev = '11067';

// const modelUrlForDev = 'https://evi-hildesheim.hausversorger.de';
// const tenantIdForDev = '11202';

// const modelUrlForDev = 'https://stadtwerk-tauberfranken.hausversorger.de';
// const tenantIdForDev = '11008';
//
// const modelUrlForDev = 'https://mein-zuhause-energie-schwaben.dev.hvm.impltech.online';
// const tenantIdForDev = '11384';

const modelUrlForDev = 'https://energie-schwaben.hausversorger.de';
const tenantIdForDev = '11002';

// const modelUrlForDev = 'https://demo-company.hausversorger.de';
// const tenantIdForDev = '11289';

export const useGetEndUserDemandAreas = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const { hostname } = window.location;

  const modelUrl = isProductionBuild
    ? hostname === END_USER_HARDCODED_DOMAIN
      ? END_USER_HARDCODED_MODEL_URL
      : window.location.origin
    : modelUrlForDev;
  const tenantId = isProductionBuild ? tenantAndProject?.tenantId : tenantIdForDev;

  const { data: endUserData, isLoading: isLoadingEndUserData } = useGetEndUserDemandAreasQuery(
    {
      modelUrl,
      tenantId: String(tenantId),
    },
    { skip: isGuestMode },
  );

  const { data: guestUserData, isLoading: isLoadingGuestUserData } = useGetPublicDemandAreasQuery(
    { tenantId: Number(tenantId), projectId: Number(tenantAndProject?.projectId) },
    { skip: !tenantId || !tenantAndProject?.projectId || !isGuestMode },
  );

  return {
    data: isGuestMode ? guestUserData : endUserData,
    isLoading: isGuestMode ? isLoadingGuestUserData : isLoadingEndUserData,
  };
};
