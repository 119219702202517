import { FC, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { useAppSelector } from '../../../../lib/store/hooks';
import { TopLeftElement } from './components/TopLeftElement';
import { TopRightElement } from './components/TopRightElement';
import { BottomLeftElement } from './components/BottomLeftElement';
import { BottomRightElement } from './components/BottomRightElement';

type Props = {
  demandAreaColor: string;
  demandAreas: DemandArea[];
  currentDemandArea: DemandArea;
  onAreaClick: (index: number) => void;
  onClickCategory: (category: Category) => void;
};

export const SmallDemandAreaHouseNew: FC<Props> = ({
  demandAreaColor,
  demandAreas,
  currentDemandArea,
  onAreaClick,
  onClickCategory,
}) => {
  const [isHoveredId, setIsHoveredId] = useState<number | null>(null);

  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const handleAreaClick = (area: DemandArea) => {
    onAreaClick(demandAreas.findIndex((item) => item.id === area.id));

    const sortedCategories = sortCategoriesByPriority(area.categories);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (area.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    if (category) {
      onClickCategory(category);
    }
  };

  const [areaTopLeft, areaTopRight, areaBottomLeft, areaBottomRight] = demandAreas;
  const demandAreaColors = {
    [areaTopLeft.id]: parseJSON(areaTopLeft.color),
    [areaTopRight.id]: parseJSON(areaTopRight.color),
    [areaBottomLeft.id]: parseJSON(areaBottomLeft.color),
    [areaBottomRight.id]: parseJSON(areaBottomRight.color),
  };

  const getCategoryCardStyle = (elementId: number | null) => {
    const outerDemandAreaColor = elementId ? demandAreaColors[elementId] : '';

    return isHoveredId === elementId
      ? `color-mix(in srgb, ${outerDemandAreaColor.value}, #FFFFFF 83%)`
      : `color-mix(in srgb, ${outerDemandAreaColor.value}, #FFFFFF 73%)`;
  };

  const handleMouseEnter = (id: number | null) => setIsHoveredId(id);
  const handleMouseLeave = () => setIsHoveredId(null);

  return (
    <div className={styles.smallHouseContainer}>
      <div
        role="presentation"
        className={styles.topLeftElement}
        onClick={() => handleAreaClick(areaTopLeft)}
        onMouseEnter={() => handleMouseEnter(areaTopLeft.id || null)}
        onMouseLeave={handleMouseLeave}
      >
        <TopLeftElement
          fill={
            currentDemandArea.id === areaTopLeft.id ? demandAreaColor : getCategoryCardStyle(areaTopLeft.id)
          }
        />
      </div>
      <div
        role="presentation"
        className={styles.topRightElement}
        onClick={() => handleAreaClick(areaTopRight)}
        onMouseEnter={() => handleMouseEnter(areaTopRight.id || null)}
        onMouseLeave={handleMouseLeave}
      >
        <TopRightElement
          fill={
            currentDemandArea.id === areaTopRight.id ? demandAreaColor : getCategoryCardStyle(areaTopRight.id)
          }
        />
      </div>
      <div
        role="presentation"
        className={styles.bottomLeftElement}
        onClick={() => handleAreaClick(areaBottomLeft)}
        onMouseEnter={() => handleMouseEnter(areaBottomLeft.id || null)}
        onMouseLeave={handleMouseLeave}
      >
        <BottomLeftElement
          fill={
            currentDemandArea.id === areaBottomLeft.id
              ? demandAreaColor
              : getCategoryCardStyle(areaBottomLeft.id)
          }
        />
      </div>
      <div
        role="presentation"
        className={styles.bottomRightElement}
        onClick={() => handleAreaClick(areaBottomRight)}
        onMouseEnter={() => handleMouseEnter(areaBottomRight.id || null)}
        onMouseLeave={handleMouseLeave}
      >
        <BottomRightElement
          fill={
            currentDemandArea.id === areaBottomRight.id
              ? demandAreaColor
              : getCategoryCardStyle(areaBottomRight.id)
          }
        />
      </div>
    </div>
  );
};
