import { Form, Input, message } from 'antd';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirmResetPasswordMutation } from '../../../../lib/store/api/auth';
import styles from './styles.module.scss';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { secToMs } from '../../../../lib/utils/helpers';
import { useTranslation } from 'react-i18next';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const MESSAGE_SHOWING_TIME = 4; // seconds

const setUpPassword = 'pages.setUpPassword';

export const EndUserNewPasswordCard = () => {
  const { demandArea, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const [form] = Form.useForm();
  const logoS3Url = demandArea?.tenant?.logo?.s3Url;
  const [confirmResetPassword, { isLoading }] = useConfirmResetPasswordMutation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const redirectToLogin = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path, { replace: true });
  };

  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (formValues: { password: string }) => {
    try {
      await confirmResetPassword({
        resetPasswordHash: token ?? '',
        newPassword: formValues.password,
        tenantId: String(tenantAndProject?.tenantId),
      }).unwrap();

      messageApi.success(t(`${setUpPassword}.successMessage`));
      setTimeout(redirectToLogin, secToMs({ sec: MESSAGE_SHOWING_TIME }));
      //eslint-disable-next-line
    } catch (err: any) {
      if (err?.data?.detail === 'Password does not comply with security policies') {
        messageApi.error(t(`${setUpPassword}.passwordContainPersonalData`));
      } else {
        messageApi.error(t(`${setUpPassword}.badLinkErrorMessage`));
      }
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div>
        <img className={styles.logo} alt="logo" src={logoS3Url} />
      </div>

      <div className={styles.main}>
        <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
          {t(`${setUpPassword}.passwordReset`)}
        </EndUserTypography>
        <EndUserTypography type="body" component="Text" className={styles.text}>
          {t(`${setUpPassword}.passwordResetRequirements`)}
        </EndUserTypography>
        <>
          <Form form={form} onFinish={onFinish}>
            <FormItem
              className={styles.formItem}
              name={FormItemName.PASSWORD}
              rules={[
                {
                  required: true,
                  message: t(`${setUpPassword}.requirements.empty`),
                },
                {
                  min: 8,
                  message: t(`${setUpPassword}.requirements.min`),
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
                  message: t(`${setUpPassword}.requirements.pattern`),
                },
              ]}
            >
              <FormInput as={Input.Password} label={t(`${setUpPassword}.label`)} />
            </FormItem>
            <FormItem
              className={styles.formItem}
              name={FormItemName.REPEAT_PASSWORD}
              rules={[
                {
                  required: true,
                  message: t(`${setUpPassword}.requirements.repeatEmpty`),
                },
                //eslint-disable-next-line
                ({ getFieldValue }: any) => ({
                  //eslint-disable-next-line
                  validator(_: any, value: any) {
                    if (!value || getFieldValue(FormItemName.PASSWORD) === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t(`${setUpPassword}.requirements.similar`)));
                  },
                }),
              ]}
            >
              <FormInput as={Input.Password} label={t(`${setUpPassword}.label2`)} />
            </FormItem>
            <EndUserButton
              disabled={isLoading}
              className={styles.sendButton}
              htmlType="submit"
              type="primary"
            >
              {t(`${setUpPassword}.saveButton`)}
            </EndUserButton>
          </Form>
        </>
      </div>

      <div className={styles.footer}>
        <EndUserAuthBottomButtons />
      </div>
    </div>
  );
};
