import {
  LogInDataResponseT,
  LogInPayloadT,
  SetUpPasswordPayloadT,
  UserDataT,
  UpdateAccessTokenPayloadT,
  ResetPasswordInitiatingT,
  ResetPasswordPayloadT,
  GetEndUserT,
  ResendRegistrationMailT,
  EmailApproveT,
  SignUpPayloadT,
  RestorePasswordT,
  ConfirmResetPasswordPayloadT,
} from '../../types/auth';
import { api, AuthURI } from './';
import { UpdateUserDataPayloadT } from '../../types/endUser';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    logIn: build.mutation<LogInDataResponseT, LogInPayloadT>({
      query: (data: LogInPayloadT) => ({
        url: data.isEndUser ? AuthURI.END_USER_LOG_IN : AuthURI.LOG_IN,
        method: 'POST',
        body: data,
        headers: data?.isEndUser
          ? {
              'X-TENANT': String(data?.tenantId),
            }
          : {},
      }),
    }),
    updateToken: build.mutation({
      query: (data: UpdateAccessTokenPayloadT) => ({
        url: AuthURI.UPDATE_ACCESS_TOKEN,
        method: 'POST',
        body: data,
      }),
    }),
    setUpPassword: build.mutation({
      query: (data: SetUpPasswordPayloadT) => ({
        url: AuthURI.SET_PASSWORD,
        method: 'POST',
        body: data,
      }),
    }),
    getEndUserData: build.query<UserDataT, GetEndUserT>({
      query: (data: GetEndUserT) => ({
        url: 'end-user/account',
        headers: {
          'X-TENANT': String(data?.tenantId),
        },
      }),
    }),
    deleteEndUserAccount: build.mutation({
      query: ({ tenantId }) => ({
        url: `end-user/account/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    resetPasswordInitiation: build.mutation({
      query: (data: ResetPasswordInitiatingT) => ({
        url: AuthURI.RESET_PASSWORD_INITIATING,
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: build.mutation({
      query: (data: ResetPasswordPayloadT) => ({
        url: AuthURI.RESET_PASSWORD,
        method: 'POST',
        body: data,
      }),
    }),
    signUp: build.mutation({
      query: (data: SignUpPayloadT) => ({
        url: AuthURI.END_USER_SIGN_UP,
        headers: {
          'X-TENANT': String(data.tenantId),
        },
        method: 'PUT',
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),
    resendRegistrationMail: build.mutation({
      query: ({ pseudoCode, tenantId }: ResendRegistrationMailT) => ({
        url: AuthURI.RESEND_MAIL,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
        params: { pseudoCode },
      }),
    }),
    emailApprove: build.mutation<void, EmailApproveT>({
      query: ({ tenantId, token }) => ({
        url: AuthURI.EMAIL_APPROVE,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {},
        params: { token },
      }),
    }),
    restorePassword: build.mutation<void, RestorePasswordT>({
      query: ({ email, projectId, tenantId }) => ({
        url: AuthURI.RESTORE_PASSWORD,
        method: 'POST',
        params: { email, projectId },
        body: {},
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    confirmResetPassword: build.mutation<void, ConfirmResetPasswordPayloadT>({
      query: ({ resetPasswordHash, newPassword, tenantId }) => ({
        url: AuthURI.CONFIRM_RESET_PASSWORD,
        method: 'POST',
        body: { resetPasswordHash, newPassword },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    updateEndUserAccount: build.mutation<unknown, UpdateUserDataPayloadT>({
      query: ({
        tenantId,
        agreeUseName,
        agreeExpandUserExperience,
        agreeInformByEmail,
        agreeInformByPhone,
        agreeInformByPost,
      }) => ({
        url: 'end-user/account/update',
        method: 'PATCH',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {
          agreeUseName,
          agreeExpandUserExperience,
          agreeInformByEmail,
          agreeInformByPhone,
          agreeInformByPost,
        },
      }),
    }),
    getUserData: build.query<UserDataT, void>({
      query: () => ({
        url: AuthURI.ACCOUNT,
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useSignUpMutation,
  useLazyGetUserDataQuery,
  useGetEndUserDataQuery,
  useLazyGetEndUserDataQuery,
  useSetUpPasswordMutation,
  useResetPasswordInitiationMutation,
  useResetPasswordMutation,
  useResendRegistrationMailMutation,
  useEmailApproveMutation,
  useConfirmResetPasswordMutation,
  useRestorePasswordMutation,
  useUpdateEndUserAccountMutation,
  useDeleteEndUserAccountMutation,
} = authApi;
