import { FC, useEffect } from 'react';
import { Card as AppCard, Form, message, Typography } from 'antd';
import { AppTitle } from '../AppTitle';
import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

import { Button as SendEmailButton } from '../../../../components/Button';

import { useNavigate } from 'react-router-dom';
import { ResetPasswordFormItem } from '../ResetPasswordFormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { ButtonLink } from '../../../../components/ButtonLink';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useResetPasswordInitiationMutation } from '../../../../lib/store/api/auth';
import { ResetPasswordInitiatingT } from '../../../../lib/types/auth';
import { END_USER_FRENCH_TENANT_MODEL_NAME, EXCEPTION_CODE } from '../../../../lib/utils/constants';
import { useAppSelector } from '../../../../lib/store/hooks';

const { Text } = Typography;

const getLinkForResetPassword = 'pages.getLinkForResetPassword';
const tittle = `${getLinkForResetPassword}.tittle`;
const subTitle = `${getLinkForResetPassword}.subTitle`;

export const GetLinkForResetPasswordCard: FC = () => {
  const [resetPasswordInitiation, { isLoading, error }] = useResetPasswordInitiationMutation();
  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const errors = form.getFieldsError();

  const isSubmitButtonAvailable = form.isFieldTouched(FormItemName.EMAIL) && errors[0].errors.length === 0;

  const onFinish = async (formValues: Record<string, string>) => {
    const formattedFormValues = {
      [FormItemName.EMAIL]: formValues[FormItemName.EMAIL].trim(),
    } as ResetPasswordInitiatingT;
    const data = await resetPasswordInitiation(formattedFormValues);

    if ('data' in data) {
      messageApi.success(t(`${getLinkForResetPassword}.success`));
    }
  };

  const redirectToLogin = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path);
  };

  useEffect(() => {
    if (error && !isLoading && 'status' in error) {
      let msg = 'Internal error';
      const value = form.getFieldValue(FormItemName.EMAIL);

      if (error.status === 400) {
        msg = t(`${getLinkForResetPassword}.errors.doesNotExist`, {
          email: value,
        });
      }

      if (error.status === 403) {
        const jsonError = JSON.stringify(error);
        const objectError = JSON.parse(jsonError);

        if (objectError.data.exceptionCode === EXCEPTION_CODE.ERROR_030) {
          msg = t(`${getLinkForResetPassword}.errors.deactivated`, {
            email: value,
          });
        }
      }

      messageApi.error(msg);
    }
  }, [error, form, isLoading, messageApi, t]);

  return (
    <AppCard bodyStyle={{ padding: 0 }} className={styles.wrapper}>
      <AppTitle className={styles.title}>
        <Trans
          className={styles.title}
          i18nKey={tittle}
          components={{
            regularText: <span className={styles.regularTitle} />,
          }}
        />
      </AppTitle>
      <Text className={styles.subTitle}>
        <Trans
          i18nKey={subTitle}
          components={{
            regularText: <span className={styles.regularTitle} />,
          }}
        />
      </Text>
      {contextHolder}

      <Form className={styles.form} form={form} onFinish={onFinish} feedbackIcons={undefined}>
        {
          (() => {
            return (
              <>
                <ResetPasswordFormItem />
                <SendEmailButton
                  type="primary"
                  style={{ marginTop: 40 }}
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!isSubmitButtonAvailable}
                >
                  {t(`${getLinkForResetPassword}.actionButton`)}
                </SendEmailButton>
              </>
            );
          }) as any
        }
      </Form>

      <ButtonLink containerStyle={{ marginTop: 16 }} onClick={redirectToLogin} type="link">
        {t(`${getLinkForResetPassword}.secondaryButton`)}
      </ButtonLink>
    </AppCard>
  );
};
