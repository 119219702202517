import { Dispatch, FC } from 'react';
import { FormItemName } from '../../../../lib/models/Form';
import { Card, Form, FormInstance, Space, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormCardButton } from '../FormCardButton';
import { FontColorFormItem } from '../../../../features/form-items/FontColorFormItem';
import { ButtonLinkRow } from '../ButtonLinkRow';
import { useGetBrandKitColors } from '../../hooks/useGetBrandKitColors';
import { DesignSettingsDataT } from '../../types';

import styles from './index.module.scss';

const designTexts = 'pages.designSettingsTab';
const addLink = 'generic.addLink';

const { Text } = Typography;

type Props = {
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
  form: FormInstance;
};

export const FooterCard: FC<Props> = ({ designSettings, setDesignSettings, form }) => {
  const { t } = useTranslation();
  const { footerConfig } = designSettings;

  const colors = useGetBrandKitColors();

  const fieldHandleChange = (fieldName: string, value: boolean | string) =>
    setDesignSettings({ ...designSettings, footerConfig: { ...footerConfig, [fieldName]: value } });

  const selectFooterBackgroundColor = (value: boolean | string) => {
    if (value) {
      fieldHandleChange('backgroundColor', value);
    }
  };

  return (
    <Card
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Switch
              checked={footerConfig.isFooterOn}
              onChange={(checked) => fieldHandleChange('isFooterOn', checked)}
              size="small"
            />
            <Text className={styles.title}>{t(`${designTexts}.footer`)}</Text>
          </div>
          <div>
            <Text type="secondary" className={styles.text}>
              {t(`${designTexts}.background`)}
            </Text>

            <Space size={20} className={styles.space}>
              <FontColorFormItem
                selectProps={{ menuPlacement: 'top' }}
                value={footerConfig.backgroundColor}
                onChange={selectFooterBackgroundColor}
                colors={colors}
                className={styles.colorFormSection}
                formItemProps={{ className: styles.colorFormItem }}
              />
              <div className={styles.switchWrapper}>
                <Switch
                  checked={footerConfig.isBackgroundShadow}
                  onChange={(checked) => fieldHandleChange('isBackgroundShadow', checked)}
                  size="small"
                />
                <Text>{t(`${designTexts}.shadow`)}</Text>
              </div>
            </Space>
          </div>
        </>
      }
    >
      <Text type="secondary" className={styles.text}>
        {t(`${designTexts}.buttonLinks`)}
      </Text>
      <Form.List name={FormItemName.BUTTON_LINKS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <ButtonLinkRow
                form={form}
                key={field.key}
                name={field.name}
                remove={remove}
                index={index + 1}
              />
            ))}
            <FormCardButton onClick={() => add()} className={styles.addLinkButton}>
              + {t(addLink)}
            </FormCardButton>
          </>
        )}
      </Form.List>
    </Card>
  );
};
