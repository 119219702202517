import React, { FC, useState } from 'react';
import { Avatar, Dropdown, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { NavLink } from 'react-router-dom';
import { ROUTER_PATHS } from '../../lib/utils/router-paths';
import { useLocalStorageItem } from '../../lib/hooks/useLocalStorageItem';
import { ACCESS_TOKEN_NAME } from '../../lib/utils/constants';
import { EndUserButton } from '../EndUserButton';
import { ButtonLink } from '../ButtonLink';
import { EndUserTypography } from '../EndUserTypography';
import { ReactComponent as PersonIcon } from '../../assets/icons/person.svg';
import { setGuestMode, setMatomoTrackingForGuest } from '../../lib/store/slices/endUserSlice';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';
import { appLogout } from '../../lib/utils/helpers';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Text } = Typography;
const shared = 'endUser.shared';

type Props = {
  backgroundColor?: string;
  handleDeleteProfile: any;
};

export const EndUserProfilePopup: FC<Props> = ({ backgroundColor, handleDeleteProfile }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth }) => auth);
  const { t } = useTranslation();

  const [accessToken] = useLocalStorageItem(ACCESS_TOKEN_NAME);

  const handleVisibleChange = () => {
    sendDataToAnalytics('Profile Pop Up', `${visible ? 'close' : 'open'}`);

    setVisible((prev) => !prev);
  };
  const handleLogoutClick = () => {
    sendDataToAnalytics('Profile Pop Up', 'logout');

    appLogout(dispatch);
  };

  const handleLogInClick = () => {
    sendDataToAnalytics('Profile Pop Up', 'Redirect to login');
    dispatch(setGuestMode(false));
    dispatch(setMatomoTrackingForGuest({ trackingEnabled: false }));
    appLogout(dispatch);

    if (window._paq) {
      window._paq.push(['forgetConsentGiven']);
    }
  };

  const handleClickStatus = () => {
    sendDataToAnalytics('Navigation', 'Seitenwechsel', 'User ruft persönliches Profil auf');

    setVisible((prev) => !prev);
  };

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById('root') || document.body}
      onOpenChange={handleVisibleChange}
      overlayClassName={styles.profile_dropdown}
      dropdownRender={() => (
        <div className={styles.profile_dropdown_wrapper}>
          <div className={styles.profile_user_block}>
            <Avatar className={styles.profile_user_avatar} style={{ backgroundColor }} size={40}>
              <PersonIcon fill="#fff" />
            </Avatar>
            <div className={styles.profile_user_info}>
              <Text className={`${styles.profile_user_text} ${styles.profile_user__bold}`}>
                {user?.email}
              </Text>
            </div>
          </div>
          <NavLink
            to={ROUTER_PATHS.pointsOverview}
            className={styles.profile_dropdownItem}
            onClick={handleClickStatus}
          >
            <Text> {t(`${shared}.pointsOverview`)} </Text>
          </NavLink>
          <hr className={styles.profile_hr} />
          <Text className={styles.profile_dropdownItem} onClick={handleDeleteProfile}>
            {t(`${shared}.removeAccount`)}
          </Text>
          <hr className={styles.profile_hr} />
          <ButtonLink containerStyle={{ textAlign: 'left' }} onClick={handleLogoutClick}>
            <EndUserTypography className={styles.profile_link} type="textLink" component="Text">
              {t(`${shared}.logout`)}
            </EndUserTypography>
          </ButtonLink>
        </div>
      )}
    >
      {!accessToken ? (
        <EndUserButton type="secondary" className={styles.profile_button} onClick={handleLogInClick}>
          {t(`${shared}.login`)}
        </EndUserButton>
      ) : (
        <Avatar
          className={styles.profile_user_avatar}
          style={{
            margin: 0,
            cursor: 'pointer',
            backgroundColor,
          }}
          size={40}
        >
          <PersonIcon fill="#fff" />
        </Avatar>
      )}
    </Dropdown>
  );
};
