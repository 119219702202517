import { FC } from 'react';
import { Form, FormInstance, Space } from 'antd';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { IconButton } from '../../../../components/IconButton';

import styles from './index.module.scss';
import { FormSection } from '../../../../components/FormSection';
import { FooterOperation } from '../../types';
import { AppSelect } from '../../../../components/AppSelect';

const linkUrl = 'pages.designSettingsTab.formItems.linkUrl';
const linkLabel = 'pages.designSettingsTab.formItems.linkLabel';
const operation = 'pages.designSettingsTab.formItems.operation';

type Props = {
  name: number;
  index: number;
  remove: (arg: number | number[]) => void;
  form: FormInstance;
};

export const ButtonLinkRow: FC<Props> = ({ name, index, remove, form }) => {
  const { t } = useTranslation();

  const operationOptions = [
    {
      title: t(`${operation}.values.redirect`),
      value: FooterOperation.REDIRECT,
    },
    {
      title: t(`${operation}.values.form`),
      value: FooterOperation.FORM,
    },
  ];

  const fieldValue = Form.useWatch([FormItemName.BUTTON_LINKS, index - 1], form);
  const isFormOperation = fieldValue?.operation?.value === FooterOperation.FORM;

  return (
    <Space size="middle" className={styles.space}>
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.LINK_NAME],
          style: { width: 280 },
          rules: [{ required: true, message: t(`${linkLabel}.errors.required`) }],
        }}
        textFieldProps={{ label: t(`${linkLabel}.label`, { index }) }}
      />
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.OPERATION],
          style: { width: 200 },
        }}
        className={styles.buttonLink}
      >
        <AppSelect
          options={operationOptions}
          label={t(`${operation}.label`)}
          defaultValue={FooterOperation.REDIRECT}
        />
      </FormSection>
      {!isFormOperation && (
        <FormSection
          className={styles.buttonLink}
          formItemProps={{
            hasFeedback: true,
            name: [name, FormItemName.LINK_URL],
            style: { width: 340 },
            rules: [
              { required: !isFormOperation, message: t(`${linkUrl}.errors.required`) },
              { type: 'url', message: t(`${linkUrl}.errors.invalid`) },
            ],
          }}
          textFieldProps={{
            label: t(`${linkUrl}.label`),
            prefix: <LinkIcon />,
          }}
        />
      )}

      <div className={styles.trashButton}>
        <IconButton onClick={() => remove(name)} icon={<TrashIcon className={styles.trashIcon} />} />
      </div>
    </Space>
  );
};
