import { useNavigate } from 'react-router-dom';
import React, { FC } from 'react';
import { Button } from 'antd';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { CoreVisualSectionT } from '../../../../lib/types/sections';

import styles from './index.module.scss';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { HouseDesktop } from '../../../../components/HouseDesktop';
import { FinderButton } from '../../../../components/FinderButton';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

type Props = {
  demandAreas: DemandArea[];
  content?: CoreVisualSectionT;
};

export const EndUserHouseModelDesktop: FC<Props> = ({ demandAreas, content }) => {
  const dispatch = useAppDispatch();
  const { demandArea, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const navigate = useNavigate();
  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Bedarfsbereich',
      `Aufruf Bedarfsbereich ${name}`,
      `User exploriert Produkte aus Bedarfsbereich ${name}`,
    );

    const demandArea = demandAreas.find((area) => area.id === areaId);

    const sortedCategories = sortCategoriesByPriority(demandArea?.categories || []);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandArea?.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    setTimeout(() => {
      dispatch(setCurrentCategory({ category }));
    }, 100);

    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  const handleCategoryClick = (areaId: number, category: Category) => {
    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);

    setTimeout(() => dispatch(setCurrentCategory({ category })), 10);
  };

  const handleRoundButtonClick = () => {
    sendDataToAnalytics(
      'Interesse für Punkte(-status)',
      'Seitenwechsel',
      'User möchte erhaltenen Bonus spenden',
    );

    navigate(`/${content?.buttonRedirectTo}`);
  };

  const showButton = demandArea?.tenant.name !== 'EnergieSchwaben' && content?.buttonText;

  return (
    <div
      className={styles.section}
      style={{
        backgroundImage: `linear-gradient(76deg, rgba(0, 0, 0, 0.51) 5.37%, rgba(0, 0, 0, 0.46) 27.78%, rgba(0, 0, 0, 0.00) 90.96%), url(${content?.backgroundImage?.s3Url})`,
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography type="headlineFirst" component="Title" className={styles.title}>
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography
          type="headlineThird"
          component="Title"
          style={{ color: '#fff' }}
          className={styles.subTitle}
        >
          {content?.headline2}
        </EndUserTypography>

        <FinderButton
          showFinderButton={content?.finderEnabled}
          finderButtonText={content?.finderButtonText}
          finderButtonUrl={content?.finderButtonLink}
          recommendForAllCategories={true}
        />
      </div>
      <div className={styles.mainContainer}>
        {showButton && (
          <Button onClick={handleRoundButtonClick} className={styles.circleButton}>
            {content?.buttonText}
          </Button>
        )}
        <HouseDesktop
          handleKeyDown={handleKeyDown}
          handleAreaClick={handleAreaClick}
          demandAreas={demandAreas}
          handleCategoryClick={handleCategoryClick}
        />
      </div>
    </div>
  );
};
