import { FC, useEffect, useRef } from 'react';
import { CategoryCard } from '../CategoryCard';
import Slider from 'react-slick';
import styles from './index.module.scss';
import { Category } from '../../../../lib/types/endUser';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { useGetCategoriesGridContainer } from '../../hooks/useGetCategoriesGridContainer';
import { useNavigate } from 'react-router-dom';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { SectionName } from '../../../../lib/models/Section';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

let firstClientX = 0;
let clientX = 0;

const preventTouch = (e: TouchEvent) => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();

    return false;
  }
};

const touchStart = (e: TouchEvent) => {
  firstClientX = e.touches[0].clientX;
};

const settings = {
  infinite: false,
  centerMode: false,
  speed: 400,
  variableWidth: true,
  slidesToShow: 1,
  swipeToSlide: true,
  initialSlide: 0,
  easing: 'linear',
};

type Props = {
  categories?: Category[];
};

export const CategoriesList: FC<Props> = ({ categories }) => {
  const carouselRef = useRef<Slider>(null);
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const { isDesktop } = useContainerQuery();
  const { windowWidth } = useWindowResize();

  const gridContainerStyle = useGetCategoriesGridContainer({ countOfCategories: Number(categories?.length) });

  const handleCategoryClick = (areaId: number, categoryId: number, name: string) => {
    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`, { state: { scrollTo: SectionName.PRODUCT_DETAILS } });

    const category = categories?.find((item) => item.id === categoryId);

    sendDataToAnalytics(
      'Produktwelt',
      'Auswahl eines Produkts aus der Produktwelt',
      `User ruft das Produkt ${name} aus der Produktwelt auf`,
    );

    setTimeout(() => {
      dispatch(setCurrentCategory({ category: category }));
    }, 10);
  };

  const beforeChange = (prev: number, next: number) => {
    if (carouselRef.current) {
      const prevSlideElement = carouselRef.current.innerSlider?.list?.querySelector(`[data-index="${prev}"]`);
      const nextSlideElement = carouselRef.current.innerSlider?.list?.querySelector(`[data-index="${next}"]`);
      setTimeout(() => {
        prevSlideElement?.classList.remove('next-slide-anim');
        nextSlideElement?.classList.add('next-slide-anim');
      });
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          capture: false,
        });
      }
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      {isDesktop || windowWidth < 1364 ? (
        <div ref={containerRef}>
          <Slider ref={carouselRef} {...settings} beforeChange={beforeChange}>
            {categories?.map(({ id, name, icon, points, demandAreaId, categoryColor, covered }) => (
              <div key={id}>
                <CategoryCard
                  onClick={() => handleCategoryClick(demandAreaId, id, name)}
                  active={covered}
                  name={name}
                  icon={icon}
                  points={points}
                  pointUnits={demandArea?.project?.pointUnits}
                  categoryColor={categoryColor}
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className={styles.gridContainer}>
          {categories?.map(({ id, name, icon, points, demandAreaId, categoryColor, covered }) => (
            <CategoryCard
              onClick={() => handleCategoryClick(demandAreaId, id, name)}
              cardWidth={gridContainerStyle.itemWidth}
              key={id}
              active={covered}
              name={name}
              icon={icon}
              points={points}
              pointUnits={demandArea?.project?.pointUnits}
              categoryColor={categoryColor}
            />
          ))}
        </div>
      )}
    </div>
  );
};
