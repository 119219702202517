import { FC, useEffect, useState } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { trimForm } from '../../../../lib/utils/helpers';
import { prepareTenantForm, getFieldsData } from '../../helpers/utils';
import { MAX_FILE_SIZE_TENANT_LOGO, initialMediaTenantState } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { TenantNameFormItem } from '../FormItems/TenantNameFormItem';
import { CommentsFormItem } from '../FormItems/CommentsFormItem';
import { ClientNameFormItem } from '../FormItems/ClientNameFormItem';
import { ClientAddressFormItem } from '../FormItems/ClientAddressFormItem';
import { AddressLineTwoFormItem } from '../FormItems/AddressLineTwoFormItem';
import { PlzFormItem } from '../FormItems/PlzFormItem';
import { ClientCityFormItem } from '../FormItems/ClientCityFormItem';
import { ClientEmailFormItem } from '../FormItems/ClientEmailFormItem';
import { ClientPhoneFormItem } from '../FormItems/ClientPhoneFormItem';
import { FileUploader } from '../../../../components/FileUploader';
import { UsersFormItem } from '../FormItems/UsersFormItem';
import { TenantModalFormValuesT } from '../../types';
import { EditTenantPayload } from '../../../../lib/types/tenants';
import { Mutation } from '../../../../lib/types/store';
import { UploadResponseT } from '../../../../lib/types/entities';
import { MIMEType, TargetType } from '../../../../lib/types/media';
import { useAppSelector } from '../../../../lib/store/hooks';
import { MessageInstance } from 'antd/es/message/interface';
import { UploadImageLabel } from '../../../../components/UploadImageLabel';
import { DomainsFormItem } from '../FormItems/DomainsFormItem';

import styles from './index.module.scss';
import { ClientLegalNoticeUrlFormItem } from '../FormItems/ClientLegalNoticeUrlFormItem';
import { ClientHelpAndContactUrlFormItem } from '../FormItems/ClientHelpAndContactUrlFormItem';
import { ClientWebsiteFormItem } from '../FormItems/ClientWebsiteFormItem';
import { DataProtectionUrlFormItem } from '../FormItems/DataProtectionUrlFormItem';
import { AGBFormItem } from '../FormItems/AGBFormItem';

const { Text } = Typography;

type Props = {
  form: FormInstance;
  additionalInfo: string;
  tenantId: number;
  messageApi: MessageInstance;
  onSubmit: Mutation<EditTenantPayload>;
};

export const EditTenantForm: FC<Props> = ({ form, messageApi, onSubmit, tenantId, additionalInfo }) => {
  const { t } = useTranslation();

  const [tenantLogoState, setTenantLogoState] = useState<UploadResponseT | null>(null);
  const [uploadingLogo, setUploadingLogo] = useState(false);

  const selectedTenant = useAppSelector(({ tenants }) => tenants.selectedTenant);

  useEffect(() => {
    if (selectedTenant) {
      form.setFields(getFieldsData(selectedTenant));
      setTenantLogoState(selectedTenant.logo);
    }
  }, [selectedTenant, form]);

  const onFinish = async (values: TenantModalFormValuesT) => {
    const trimmedForm = trimForm(values);
    const preparedForm = prepareTenantForm(trimmedForm);
    const formForSubmit = {
      ...preparedForm,
      logo: tenantLogoState,
      id: tenantId,
      activated: selectedTenant?.activated, // activated field is needed, otherwise BE will be broken
    };

    try {
      await onSubmit(formForSubmit).unwrap();
      messageApi.success(t('pages.tenants.editModal.success'));
    } catch (err) {
      messageApi.error(t('pages.tenants.editModal.error'));
    }
  };

  const handleChangeTenantLogo = (value: UploadResponseT | null) => {
    setTenantLogoState(value);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className={styles.wrapper}>
        <TenantNameFormItem />
        <DomainsFormItem />
        {/* <BrandkitFormItem /> TODO connect when back is ready */}
        <UsersFormItem />
        <CommentsFormItem />
        <Text className={styles.text}>{additionalInfo}</Text>
        <ClientNameFormItem />
        <ClientAddressFormItem />
        <AddressLineTwoFormItem />
        <div className={styles.rowWrapper}>
          <PlzFormItem />
          <ClientCityFormItem />
        </div>
        <div className={styles.rowWrapper}>
          <ClientEmailFormItem />
          <ClientPhoneFormItem />
        </div>
        <div className={styles.rowWrapper}>
          <ClientWebsiteFormItem />
          <ClientLegalNoticeUrlFormItem />
        </div>
        <div className={styles.rowWrapper}>
          <ClientHelpAndContactUrlFormItem />
          <DataProtectionUrlFormItem />
        </div>
        <AGBFormItem />
        <FileUploader
          receivedTenantId={selectedTenant?.id ? String(selectedTenant?.id) : undefined}
          targetType={TargetType.TENANT_LOGO}
          excludedTypes={[MIMEType.SVG]}
          afterUploadAction={(file: UploadResponseT) => handleChangeTenantLogo(file)}
          errorText={t('shared.design.beforeUploadFileError')}
          maxFileSize={MAX_FILE_SIZE_TENANT_LOGO}
          disabled={uploadingLogo}
          setUploading={setUploadingLogo}
          uploadLabel={({ onDeleteMedia }) => (
            <UploadImageLabel
              className={styles.uploadLabel}
              mediaObject={uploadingLogo ? undefined : tenantLogoState || undefined}
              uploading={uploadingLogo}
              deleteOnClick={() => handleChangeTenantLogo(initialMediaTenantState as any)}
              imgHeight={52}
              onDeleteMedia={onDeleteMedia}
              text={t('pages.tenants.formItems.chooseImageText')}
            />
          )}
        />
      </div>
    </Form>
  );
};
