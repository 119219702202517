import { Form, Input, message, Tooltip } from 'antd';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { useAppSelector } from '../../../../lib/store/hooks';
import styles from './index.module.scss';
import { AppCheckbox } from '../../../../components/AppCheckox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSignUpMutation } from '../../../../lib/store/api/auth';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import { EndUserVerifyEmail } from '../EndUserVerifyEmail';
import { BACKEND_VALIDATION_ERRORS } from '../../utils/constants';
import { parseJSON } from '../../../../lib/utils/helpers';
import { SectionName } from '../../../../lib/models/Section';
import { useGetCheckboxAgreementText } from '../../hooks/useGetCheckboxAgreementText';
import { Button } from '../../../../components/Button';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '../../../../assets/icons/InfoIcon';
import { ScrollbarWrapper } from '../../../../app/components/ScrollbarWrapper';

import { AutoSizer } from 'react-virtualized';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const signUpCard = 'endUser.signUpCard';
const mandatory = 'endUser.shared.mandatory';

export const EndUserSignUpCard = () => {
  const { demandArea, designSettings, tenantAndProject, sections } = useAppSelector(({ endUser }) => endUser);
  const dataProtectionLink = demandArea?.tenant.dataProtection || '/';
  const agbLink = demandArea?.tenant.agb || '/';
  const { t } = useTranslation();
  const currentSectionContent = sections?.[SectionName.AGREEMENTS];

  const isUpdatedAgreements = currentSectionContent?.isUpdatedAgreement;
  const renderPostCheckbox = currentSectionContent && currentSectionContent?.byPost?.length > 0;
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const navigate = useNavigate();

  const { getCheckboxText } = useGetCheckboxAgreementText({
    agbLink,
    dataProtectionLink,
    clientName: demandArea?.tenant?.clientName || '',
  });

  const [form] = Form.useForm();
  const logoS3Url = designSettings?.headerConfig?.logo?.s3Url;

  const [messageApi, contextHolder] = message.useMessage();
  const [signUp] = useSignUpMutation();
  const [pseudoCode, setPseudoCode] = useState('');
  const [searchParams] = useSearchParams();
  const pseudonym = searchParams.get('pseudocode') || null;

  useEffect(() => {
    if (pseudonym) {
      form.setFieldValue(FormItemName.PSEUDONYM, pseudonym);
    }
  }, [form, pseudonym]);

  useEffect(() => {
    form.setFields([
      {
        name: [FormItemName.REGISTRATION_EMAIL],
        value: '',
      },
      {
        name: [FormItemName.REGISTRATION_PASSWORD],
        value: '',
      },
    ]);
  }, [form]);

  const onFinish = async (formValues: Record<string, string>) => {
    try {
      await signUp({
        projectId: String(tenantAndProject?.projectId),
        pseudoCode: formValues.pseudonym,
        email: formValues.registrationEmail,
        passwordHash: formValues.registrationPassword,
        agreeUseName: formValues.agreeUseName,
        agreeExpandUserExperience: formValues.agreeExpandUserExperience,
        readTermsAndPrivacyPolicy: formValues.readTermsAndPrivacyPolicy,
        tenantId: String(tenantAndProject?.tenantId),
        agreeInformByEmail: formValues.agreeInformByEmail,
        agreeInformByPhone: formValues.agreeInformByPhone,
        agreeInformByPost: formValues.agreeInformByPost,
      }).unwrap();

      setPseudoCode(formValues.pseudonym);
    } catch (error: any) {
      let errorMessage;

      if (error.data) {
        try {
          const data = parseJSON(error.data);
          errorMessage = data?.message;

          if (data.exceptionCode) {
            const apiError = BACKEND_VALIDATION_ERRORS.get(data.exceptionCode);

            form.setFields([
              {
                name: apiError?.fieldName,
                value: formValues[apiError?.fieldName || ''],
                errors: [apiError?.message || ''],
              },
            ]);

            errorMessage =
              apiError?.message ||
              errorMessage ||
              error.data.detail ||
              error.data.message ||
              'Something went wrong';
            messageApi.error(errorMessage);
          } else {
            errorMessage = errorMessage || error.data.detail || error.data.message || 'Something went wrong';
            messageApi.error(errorMessage);
          }
        } catch (e) {
          errorMessage = errorMessage || error.data.detail || error.data.message || 'Something went wrong';
          messageApi.error(errorMessage);
        }
      }
    }
  };

  const onRedirectToLogin = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path);
  };

  const onSelectCheckboxes = () => {
    if (isUpdatedAgreements) {
      form.setFields([
        {
          name: [FormItemName.AGREE_USE_NAME],
          value: true,
        },
        {
          name: [FormItemName.READ_TERMS_AND_PRIVACY_POLICY],
          value: true,
        },
        {
          name: [FormItemName.AGREE_INFORM_BY_PHONE],
          value: true,
        },
        {
          name: [FormItemName.AGREE_INFORM_BY_EMAIL],
          value: true,
        },
        {
          name: [FormItemName.AGREE_INFORM_BY_POST],
          value: true,
        },
      ]);
    } else {
      form.setFields([
        {
          name: [FormItemName.AGREE_INFORM_BY_EMAIL],
          value: true,
        },
        {
          name: [FormItemName.AGREE_USE_NAME],
          value: true,
        },
        {
          name: [FormItemName.READ_TERMS_AND_PRIVACY_POLICY],
          value: true,
        },
      ]);
    }

    form.submit();
  };

  return (
    <>
      {!pseudoCode ? (
        <div className={styles.container}>
          {contextHolder}
          <div className={styles.header}>
            <div>
              <img className={styles.logo} alt="logo" src={logoS3Url} />
              <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
                {t(`${signUpCard}.title`)}
              </EndUserTypography>
            </div>
          </div>
          <div className={styles.main}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <ScrollbarWrapper
                    scrollerClassName={styles.scroller}
                    trackYClassName={styles.trackY}
                    styles={{ width: width, height: height }}
                  >
                    <Form form={form} onFinish={onFinish} autoComplete="off">
                      <input
                        type="email"
                        autoComplete="on"
                        value=""
                        name="email"
                        style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                        readOnly={true}
                      />
                      <input
                        type="password"
                        autoComplete="on"
                        value=""
                        name="password"
                        style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                        readOnly={true}
                      />
                      <div className={styles.inputWrapper}>
                        <Tooltip
                          title={t(`${signUpCard}.pseudoCodeHint.title`)}
                          overlayInnerStyle={{
                            width: 400,
                          }}
                        >
                          <InfoIcon className={styles.tooltip} />
                        </Tooltip>
                        <FormItem
                          className={styles.formItem}
                          hasFeedback={true}
                          name={FormItemName.PSEUDONYM}
                          rules={[
                            {
                              required: true,
                              message: t(`${signUpCard}.formItems.pseudoCode.error`),
                            },
                          ]}
                        >
                          <FormInput
                            label={t(`${signUpCard}.formItems.pseudoCode.label`)}
                            disabled={!!pseudonym}
                          />
                        </FormItem>
                      </div>

                      <FormItem
                        className={styles.formItem}
                        hasFeedback={true}
                        name={FormItemName.REGISTRATION_EMAIL}
                        rules={[
                          {
                            required: true,
                            message: t(`${signUpCard}.formItems.email.error`),
                            type: 'email',
                          },
                        ]}
                      >
                        <FormInput label={t(`${signUpCard}.formItems.email.label`)} autoComplete="none" />
                      </FormItem>
                      <FormItem
                        className={styles.formItem}
                        name={FormItemName.REGISTRATION_PASSWORD}
                        rules={[
                          {
                            required: true,
                            message: t(`${signUpCard}.formItems.password.errorEmpty`),
                          },
                          {
                            min: 8,
                            message: t(`${signUpCard}.formItems.password.errorMin`),
                          },
                          {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
                            message: t(`${signUpCard}.formItems.password.errorPattern`),
                          },
                        ]}
                      >
                        <FormInput
                          as={Input.Password}
                          label={t(`${signUpCard}.formItems.password.label`)}
                          autoComplete="none"
                        />
                      </FormItem>
                      <FormItem
                        className={styles.formItem}
                        name={FormItemName.REPEAT_PASSWORD}
                        rules={[
                          {
                            required: true,
                            message: t(`${signUpCard}.formItems.repeatPassword.errorEmpty`),
                          },
                          ({ getFieldValue }: any) => ({
                            validator(_: any, value: any) {
                              if (!value || getFieldValue(FormItemName.REGISTRATION_PASSWORD) === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t(`${signUpCard}.formItems.repeatPassword.errorRepeat`)),
                              );
                            },
                          }),
                        ]}
                      >
                        <FormInput
                          as={Input.Password}
                          label={t(`${signUpCard}.formItems.repeatPassword.label`)}
                        />
                      </FormItem>
                      <FormItem
                        name={FormItemName.AGREE_USE_NAME}
                        hasFeedback={true}
                        className={styles.checkbox}
                        valuePropName="checked"
                        rules={[
                          {
                            required: false,
                            message: '',
                            type: 'boolean',
                            transform: (value: any) => value || undefined,
                          },
                        ]}
                      >
                        <AppCheckbox
                          className={styles.checkboxContent}
                          label={
                            <EndUserTypography type="headlineSeventh" component="Text">
                              {getCheckboxText(currentSectionContent?.agreeUseName)}
                            </EndUserTypography>
                          }
                        />
                      </FormItem>
                      {isUpdatedAgreements && (
                        <>
                          <EndUserTypography
                            className={styles.checkboxText}
                            type="headlineSeventh"
                            component="Text"
                          >
                            {getCheckboxText(currentSectionContent?.byPhone1)}
                          </EndUserTypography>
                          <FormItem
                            valuePropName="checked"
                            name={FormItemName.AGREE_INFORM_BY_PHONE}
                            hasFeedback={true}
                            className={styles.checkbox}
                            rules={[
                              {
                                required: false,
                                message: '',
                                type: 'boolean',
                                transform: (value: any) => value || undefined,
                              },
                            ]}
                          >
                            <AppCheckbox
                              className={styles.checkboxContent}
                              label={
                                <EndUserTypography type="headlineSeventh" component="Text">
                                  {getCheckboxText(currentSectionContent?.byPhone2)}
                                </EndUserTypography>
                              }
                            />
                          </FormItem>
                          <FormItem
                            valuePropName="checked"
                            name={FormItemName.AGREE_INFORM_BY_EMAIL}
                            hasFeedback={true}
                            className={styles.checkbox}
                            rules={[
                              {
                                required: false,
                                message: '',
                                type: 'boolean',
                                transform: (value: any) => value || undefined,
                              },
                            ]}
                          >
                            <AppCheckbox
                              className={styles.checkboxContent}
                              label={
                                <EndUserTypography type="headlineSeventh" component="Text">
                                  {getCheckboxText(currentSectionContent?.byEmail2)}
                                </EndUserTypography>
                              }
                            />
                          </FormItem>
                          {renderPostCheckbox && (
                            <FormItem
                              valuePropName="checked"
                              name={FormItemName.AGREE_INFORM_BY_POST}
                              hasFeedback={true}
                              className={styles.checkbox}
                              rules={[
                                {
                                  required: false,
                                  message: '',
                                  type: 'boolean',
                                  transform: (value: any) => value || undefined,
                                },
                              ]}
                            >
                              <AppCheckbox
                                className={styles.checkboxContent}
                                label={
                                  <EndUserTypography type="headlineSeventh" component="Text">
                                    per Brief
                                  </EndUserTypography>
                                }
                              />
                            </FormItem>
                          )}

                          <EndUserTypography
                            className={styles.checkboxText}
                            type="headlineSeventh"
                            component="Text"
                          >
                            {getCheckboxText(currentSectionContent?.byEmail)}
                          </EndUserTypography>
                        </>
                      )}

                      {!isUpdatedAgreements && (
                        <FormItem
                          valuePropName="checked"
                          name={FormItemName.AGREE_INFORM_BY_EMAIL}
                          hasFeedback={true}
                          className={styles.checkbox}
                          rules={[
                            {
                              required: false,
                              message: '',
                              type: 'boolean',
                              transform: (value: any) => value || undefined,
                            },
                          ]}
                        >
                          <AppCheckbox
                            className={styles.checkboxContent}
                            label={
                              <EndUserTypography type="headlineSeventh" component="Text">
                                {getCheckboxText(currentSectionContent?.agreeExpandUserExperience)}
                              </EndUserTypography>
                            }
                          />
                        </FormItem>
                      )}

                      <FormItem
                        name={FormItemName.READ_TERMS_AND_PRIVACY_POLICY}
                        hasFeedback={true}
                        className={styles.checkbox}
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: '',
                            type: 'boolean',
                            transform: (value: any) => value || undefined,
                          },
                        ]}
                      >
                        <AppCheckbox
                          className={styles.checkboxContent}
                          label={
                            <EndUserTypography type="headlineSeventh" component="Text">
                              {getCheckboxText(currentSectionContent?.readTermsAndPrivacyPolicy)}
                            </EndUserTypography>
                          }
                        />
                      </FormItem>
                      <EndUserTypography
                        type="subText"
                        component="Text"
                        style={{ margin: '0 0 14px', display: 'block' }}
                      >
                        * {t(mandatory)}
                      </EndUserTypography>
                    </Form>
                    <div className={styles.footer}>
                      <EndUserAuthBottomButtons />
                    </div>
                  </ScrollbarWrapper>
                );
              }}
            </AutoSizer>
          </div>
          <div className={styles.buttonWrapper}>
            <EndUserButton
              className={styles.sendButton}
              htmlType="submit"
              type="primary"
              onClick={onSelectCheckboxes}
            >
              {t(`${signUpCard}.acceptAll`)}
            </EndUserButton>
            <EndUserButton
              className={styles.sendButton}
              htmlType="submit"
              type="secondary"
              onClick={() => form.submit()}
            >
              {t(`${signUpCard}.submit`)}
            </EndUserButton>

            <Button onClick={onRedirectToLogin} className={styles.loginRedirectButton} type="link">
              <EndUserTypography type="headlineEight">{t(`${signUpCard}.login`)}</EndUserTypography>
            </Button>
          </div>
        </div>
      ) : (
        <EndUserVerifyEmail pseudoCode={pseudoCode} />
      )}
    </>
  );
};
