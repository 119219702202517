import { BenefitsSection } from '../../modules/endUserBenefits/components/BenefitsSection';
import { EndUserDemandArea } from '../../modules/endUserDemandArea/components/EndUserDemandArea';
import { FriendsSection } from '../../modules/endUserFriends/components/FriendsSection';
import { EndUserProductSection } from '../../modules/endUserProductCarousel/components/EndUserProductSection';
import { RegionalOffersSection } from '../../modules/endUserRegionalOffers/components/RegionalOffersSection';
import { useAppSelector } from '../../lib/store/hooks';
import { useMemo } from 'react';

const eviHardcodedCategoryName1 = 'Wärme / Gas';
const eviHardcodedCategoryName2 = 'Strom';

export const EndUserDemandAreaPage = () => {
  const { tenantAndProject, selectedCategory } = useAppSelector(({ endUser }) => endUser);

  const isHauseVersorger = tenantAndProject?.subDomain === 'evi-hildesheim';

  const showFiendsSection = useMemo(() => {
    if (isHauseVersorger) {
      return (
        selectedCategory?.name === eviHardcodedCategoryName1 ||
        selectedCategory?.name === eviHardcodedCategoryName2
      );
    }

    return true;
  }, [isHauseVersorger, selectedCategory?.name]);

  return (
    <>
      <EndUserDemandArea />
      <EndUserProductSection />
      {showFiendsSection && <FriendsSection />}
      <RegionalOffersSection />
      <BenefitsSection />
    </>
  );
};
