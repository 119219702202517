import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Modal } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CrossIcon.svg';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEmailApproveMutation } from '../../../../lib/store/api/auth';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useTranslation } from 'react-i18next';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const welcomeCard = 'endUser.welcomeCard';

export const EndUserRegistrationCheckCard = () => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | undefined>();
  const [isTokenConfirmed, setIsTokenConfirmed] = useState(false);
  const { tenantAndProject, demandArea } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const [emailApprove] = useEmailApproveMutation();
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const navigate = useNavigate();

  const parseToken = useCallback(() => {
    const urlToken = searchParams.get('token');

    if (urlToken) {
      setToken(urlToken);
    }
  }, [searchParams]);

  const handleConfirm = useCallback(async () => {
    if (token) {
      try {
        await emailApprove({ token, tenantId: String(tenantAndProject?.tenantId) }).unwrap();
        setIsTokenConfirmed(true);
      } catch (e) {
        // TODO flow with rejected token TBD
        console.warn(e);
      }
    }
  }, [emailApprove, token]);

  useEffect(() => {
    parseToken();
  }, [parseToken]);

  useEffect(() => {
    handleConfirm();
  }, [handleConfirm]);

  const handleClose = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path, { replace: true });
  };

  return isTokenConfirmed ? (
    <Modal
      open={true}
      onCancel={handleClose}
      closeIcon={<CloseIcon />}
      width={422}
      className={styles.modal}
      bodyStyle={{ padding: '24px 24px 32px', margin: '-34px -24px -32px' }}
      style={{ top: '25%' }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={styles.card}>
        <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
          {t(`${welcomeCard}.title`)}
        </EndUserTypography>
        <EndUserTypography className={styles.text} type="body" component="Text" level={2}>
          {t(`${welcomeCard}.text`)}
        </EndUserTypography>
        <EndUserButton onClick={handleClose} className={styles.button} type="primary">
          <EndUserTypography className={styles.button_text} type="headlineFifth" component="Text">
            {t(`${welcomeCard}.button`)}
          </EndUserTypography>
        </EndUserButton>
      </div>
    </Modal>
  ) : null;
};
