import React, { FC, useMemo } from 'react';
import { ProductRecommendationT } from '../../../../lib/types/sections';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconT } from '../../../designSettings/types';
import cn from 'classnames';
import { EndUserWishlistButton } from '../../../../components/EndUserWishlistButton';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { SectionName } from '../../../../lib/models/Section';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { parseJSON } from '../../../../lib/utils/helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { getProductPoints } from '../../../../features/endUser/utils/helpers';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { Visibility } from '../../../../lib/types/product';
import { useAppSelector } from '../../../../lib/store/hooks';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

type Props = ProductRecommendationT & {
  arePointsShown: boolean;
  productSize?: number;
  isTablet?: boolean;
  pointsIcon?: IconT;
  isDesktopOrLaptop?: boolean;
  productCount?: number;
  isLandscape?: boolean;
  brandKit: BrandKitThemeConfiguration | null;
  isGuestMode: boolean;
  atLeastOneHasSubline?: boolean;
  productNameLineCount?: number;
};

export const ProductRecommendationCard: FC<Props> = ({
  isReasonShown,
  product,
  reason,
  arePointsShown,
  productSize,
  pointsIcon,
  isDesktopOrLaptop,
  productCount,
  isLandscape,
  isTablet,
  brandKit,
  isGuestMode,
  atLeastOneHasSubline,
  productNameLineCount,
}) => {
  const primaryColor = brandKit?.colors?.primaryColor;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allProducts } = useAppSelector(({ endUser }) => endUser);
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const showBigImage = useMemo(() => {
    return !!(productCount === 2 && (isDesktopOrLaptop || (isTablet && isLandscape)));
  }, [isDesktopOrLaptop, isLandscape, isTablet, productCount]);

  const additionalInfo = product?.additionalInfo && parseJSON(product.additionalInfo);

  const handleCardClick = () => {
    sendDataToAnalytics(
      'Interesse durch Empfehlung',
      'Auswahl eines Produkts aus den Empfehlungen',
      `User ruft das Produkt ${product.name} aus den Empfehlungen auf`,
    );

    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    if (product?.category?.visibility === Visibility.NEVER && product?.associatedProduct) {
      const associatedProduct = allProducts?.find((item) => item?.id === product?.associatedProduct);

      if (associatedProduct) {
        navigate(`/${path}/${associatedProduct.demandAreaId}`, {
          state: { scrollTo: SectionName.PRODUCT_DETAILS },
        });
        setTimeout(() => {
          dispatch(setCurrentCategory({ category: associatedProduct.category }));
        }, 10);
      }

      return;
    }

    navigate(`/${path}/${product.demandAreaId}`, {
      state: { scrollTo: SectionName.PRODUCT_DETAILS },
    });
    setTimeout(() => {
      dispatch(setCurrentCategory({ category: product.category }));
    }, 10);
  };

  const productPoints = useMemo(() => {
    return product ? getProductPoints(product) : 0;
  }, [product]);

  if (!product) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        onClick={handleCardClick}
        role="presentation"
        className={styles.container}
        style={{ width: productSize }}
      >
        <div className={styles.header}>
          <div className={styles.imageContainer}>
            <div className={styles.headerButtons}>
              <div />
              <EndUserWishlistButton
                isInWishlist={product.isWishlist}
                product={product}
                primaryColor={primaryColor}
                isGuestMode={isGuestMode}
                sectionNameForAnalytics={SectionName.PRODUCT_RECOMMENDATION}
              />
            </div>
            <img
              className={cn(styles.image, showBigImage && styles.imageBig)}
              alt="product-img"
              src={additionalInfo?.picture?.s3Url}
            />
          </div>
          <div className={styles.titleHeader}>
            <EndUserTypography
              className={styles.productName}
              type="headlineFourth"
              component="Title"
              level={4}
              style={{ height: productNameLineCount === 2 ? '3em' : 'auto' }}
            >
              {product.name}
            </EndUserTypography>

            {arePointsShown && productPoints && (
              <div className={styles.pointsContainer}>
                <EndUserTypography
                  className={styles.productPoints}
                  type="headlineFifth"
                  component="Title"
                  level={4}
                >
                  + {productPoints}
                  <IconComponent className={styles.pointsIcon} iconData={pointsIcon} color={'currentColor'} />
                </EndUserTypography>
              </div>
            )}
          </div>
          {isReasonShown && (
            <EndUserTypography className={styles.recommendation} type="subText" component="Text">
              {reason}
            </EndUserTypography>
          )}
        </div>
        {atLeastOneHasSubline && (
          <EndUserTypography
            ellipsis={{ rows: 2 }}
            className={styles.productSubLine}
            type="body"
            component="Paragraph"
          >
            {product.subline}
          </EndUserTypography>
        )}
      </div>
    </div>
  );
};
