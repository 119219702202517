import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import cx from 'classnames';
import { EndUserButton } from '../../../../components/EndUserButton';
import { ReactComponent as LetterIcon } from '../../../../assets/icons/letter-dot.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import { ButtonLink } from '../../../../components/ButtonLink';
import { message } from 'antd';
import { useResendRegistrationMailMutation } from '../../../../lib/store/api/auth';
import { useTranslation } from 'react-i18next';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const pseudoCodeCard = 'endUser.pseudoCodeCard';

type PropsT = {
  pseudoCode: string;
};

export const EndUserVerifyEmail: FC<PropsT> = ({ pseudoCode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [resendRegistrationMail] = useResendRegistrationMailMutation();

  const [messageApi, contextHolder] = message.useMessage();

  const { demandArea, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const logoS3Url = demandArea?.tenant?.logo?.s3Url;
  const modelName = demandArea?.project?.name;

  const handleNavigate = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path, { replace: true });
  };

  const handleResendEmail = async () => {
    try {
      await resendRegistrationMail({
        pseudoCode,
        tenantId: String(tenantAndProject?.tenantId),
      }).unwrap();
    } catch (e) {
      messageApi.error('failed');
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <div className={styles.card}>
          <img className={styles.logo} alt="logo" src={logoS3Url} />
          <div className={styles.card_content}>
            <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
              {t(`${pseudoCodeCard}.title`)}
            </EndUserTypography>
            <LetterIcon className={cx(styles.logo, styles.logo__mail)} />
            <EndUserTypography className={styles.text} type="body" component="Text">
              {t(`${pseudoCodeCard}.subline`, { modelName })}
            </EndUserTypography>
            <EndUserTypography className={styles.text} style={{ margin: 0 }} type="body" component="Text">
              {t(`${pseudoCodeCard}.question`)}{' '}
              <ButtonLink
                onClick={handleResendEmail}
                buttonStyle={{ padding: 0, height: 'unset' }}
                containerStyle={{ display: 'inline-block' }}
                size="large"
              >
                <EndUserTypography
                  className={cx(styles.link)}
                  style={{ fontSize: 16 }}
                  type="textLink"
                  component="Link"
                >
                  {t(`${pseudoCodeCard}.link`)}
                </EndUserTypography>
              </ButtonLink>
            </EndUserTypography>
            <div className={styles.button}>
              <EndUserButton type="secondary" onClick={handleNavigate}>
                <EndUserTypography type="headlineFifth" component="Text">
                  {t(`${pseudoCodeCard}.login`)}
                </EndUserTypography>
              </EndUserButton>
            </div>
          </div>
          <EndUserAuthBottomButtons />
        </div>
      </div>
    </>
  );
};
