import { FC } from 'react';
import { RegionalOfferCard } from '../RegionalOfferCard';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';

import styles from './index.module.scss';

import cn from 'classnames';

export const RegionalOffersSection: FC = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.REGIONAL_OFFERS];

  const handleClickOffer = (link?: string, index?: number) => () => {
    if (link) {
      sendDataToAnalytics(
        'Interesse an energie schwaben',
        'Wechsel zur Website',
        index === 0
          ? 'User nimmt Kontakt zu energie schwaben auf'
          : 'User informiert sich über Meldung von energie schwaben',
      );

      window.open(link, '_blank');
    }
  };

  const handleClickTitle = () => {
    if (demandArea?.tenant.clientName === 'Energie Schwaben') {
      const link = 'https://www.energie-schwaben.de/fileadmin/user_upload/he3_2023_WEB_kompr.pdf';
      window.open(link, '_blank');
    }
  };

  const isThereThirdOrMoreWidgets = currentSectionContent && currentSectionContent?.offers?.length >= 3;

  if (!currentSectionContent) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <EndUserTypography type="headlineSecond" component="Title" level={2} className={styles.title}>
        <div role="presentation" onClick={handleClickTitle}>
          {currentSectionContent?.headline2}
        </div>
      </EndUserTypography>

      <EndUserTypography type="headlineThird" component="Title" level={3} className={styles.subTitle}>
        {currentSectionContent?.headline3}
      </EndUserTypography>
      <div className={cn(styles.offers, isThereThirdOrMoreWidgets && styles.offersThirdMoreWidgets)}>
        {currentSectionContent?.offers?.map(({ link, image, description, title }, index) => (
          <RegionalOfferCard
            key={index}
            isThereThirdOrMoreWidgets={isThereThirdOrMoreWidgets}
            image={image}
            title={title}
            description={description}
            onClickOffer={handleClickOffer(link, index)}
          />
        ))}
      </div>
    </div>
  );
};
