import { FC } from 'react';
import { Modal } from 'antd';

type Props = {
  isModalOpen: boolean;
  close: () => void;
  youtubeVideoId?: string;
  youtubeVideoTitle?: string;
};
export const EndUserVideoFrameModal: FC<Props> = ({
  isModalOpen,
  close,
  youtubeVideoId,
  youtubeVideoTitle,
}) => {
  return (
    <Modal
      centered
      open={isModalOpen}
      width={800}
      onCancel={close}
      destroyOnClose={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: 10,
          }}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          title={youtubeVideoTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
};
