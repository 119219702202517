import { FC } from 'react';
import { DemandArea } from '../../../../lib/types/endUser';
import { CoreVisualSectionT } from '../../../../lib/types/sections';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FinderButton } from '../../../../components/FinderButton';
import { EndUserSquareTilesMobile } from '../EndUserSquareTilesMobile';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../lib/store/hooks';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

type Props = {
  demandAreas?: DemandArea[];
  content?: CoreVisualSectionT;
};

export const EndUserDemandAreaTilesMobile: FC<Props> = ({ demandAreas, content }) => {
  const navigate = useNavigate();
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;
  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Bedarfsbereich',
      `Aufruf Bedarfsbereich ${name}`,
      `User exploriert Produkte aus Bedarfsbereich ${name}`,
    );

    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(49, 49, 49, 0.3) 57.53%, rgba(0, 0, 0, 0) 100%), url(${content?.backgroundImage?.s3Url})`,
        padding: '32px 16px 16px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography
          type="headlineSecond"
          component="Title"
          className={styles.title}
          style={{ color: '#fff' }}
        >
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography
          className={styles.subTitle}
          type="headlineSixth"
          component="Title"
          style={{ color: '#fff', marginTop: 0 }}
        >
          {content?.headline2}
        </EndUserTypography>

        <FinderButton
          showFinderButton={content?.finderEnabled}
          finderButtonText={content?.finderButtonText}
          finderButtonUrl={content?.finderButtonLink}
          recommendForAllCategories={true}
        />
      </div>

      <EndUserSquareTilesMobile
        demandAreas={demandAreas}
        content={content}
        handleAreaClick={handleAreaClick}
        handleKeyDown={handleKeyDown}
      />
    </div>
  );
};
