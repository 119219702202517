import { FormItemName } from '../../../lib/models/Form';
import { ColorVariants, FontItemT } from '../../../lib/types/colors&fonts';
import { UploadMediaResponseT } from '../../../lib/types/media';

export enum AdditionalColorVariants {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  FIFTH = 'fifth',
}

export type AdditionalColorsT = {
  first: string;
  second: string;
  third: string;
  fourth: string;
  fifth: string;
};

export type DefaultIconT = {
  id: number;
  s3Url: string;
  originalFileName: string;
};

export type IconT =
  | UploadMediaResponseT
  | (DefaultIconT & {
      cdnUrl?: string;
    });

export enum ButtonShape {
  ROUND = 'round',
  MEDIUM_ROUND = 'mediumRound',
  ANGULAR = 'angular',
}

export type ButtonColorsT = {
  isFilled: boolean;
  filledColor: ColorVariants;
  isOutlined: boolean;
  outLinedColor: ColorVariants;
};

export enum FooterOperation {
  FORM = 'form',
  REDIRECT = 'redirect',
}

export type ButtonT = {
  shape: ButtonShape;
  font: FontItemT;
  type: ButtonColorsT;
};

export type FooterButtonT = {
  [FormItemName.LINK_NAME]: string;
  [FormItemName.LINK_URL]: string;
  [FormItemName.OPERATION]: {
    value: FooterOperation;
  };
};

export type HeaderConfigT = {
  isBackgroundShadow: boolean;
  backgroundColor: ColorVariants;
  logo: UploadMediaResponseT | null;
  [FormItemName.NAVIGATION]?: {
    [FormItemName.NAVIGATION_LANDING]: string;
    [FormItemName.NAVIGATION_BONUSES]: string;
    [FormItemName.NAVIGATION_DEMAND]: string;
    [FormItemName.NAVIGATION_POINT]: string;
    [FormItemName.NAVIGATION_WISHLIST]: string;
  };
};

export type FooterConfigT = {
  isFooterOn: boolean;
  isBackgroundShadow: boolean;
  backgroundColor: ColorVariants;
  [FormItemName.BUTTON_LINKS]?: FooterButtonT[];
};

export type DesignSettingsDataT = {
  additionalColors: AdditionalColorsT;
  icons: IconT[];
  bulletIcons: IconT[];
  mediaFiles: UploadMediaResponseT[];
  footerConfig: FooterConfigT;
  headerConfig: HeaderConfigT;
  primaryButton: ButtonT;
  secondaryButton: ButtonT;
};
