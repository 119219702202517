import { FC } from 'react';
import styles from './index.module.scss';
import { Form } from 'antd';
import { GeneralInfoCard } from './components/GeneralInfoCard';
import { VisibilityCard } from './components/VisibilityCard';
import { DetailedInfoCard } from './components/DetailedInfoCard';
import { PointsCard } from './components/PointsCard';
import { PriceCard } from './components/PriceCard';
import { ButtonCard } from './components/ButtonCard';
import { ActionButtons } from './components/ActionButtons';
import { useProductConfiguratorForm } from './hooks/useProductConfiguratorForm';
import { MessageInstance } from 'antd/es/message/interface';
import { ProductModals } from './components/Modals';
import { FormItemName } from '../../lib/models/Form';
import { BadgeCard } from './components/BadgeCard';
import { AgeRangeCard } from './components/AgeRangeCard';
import { RegionCard } from './components/RegionCard';
import { DistrictCard } from './components/DistrictCard';

type Props = {
  messageApi: MessageInstance;
};

export const ProductConfigurator: FC<Props> = ({ messageApi }) => {
  const [form] = Form.useForm();

  const { onSubmit, isLoading } = useProductConfiguratorForm({ form, messageApi });

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        className={styles.form}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
      >
        <GeneralInfoCard form={form} />
        <VisibilityCard />
        <DetailedInfoCard form={form} />
        <BadgeCard />
        <PointsCard form={form} />
        <PriceCard />
        <AgeRangeCard form={form} />
        <RegionCard form={form} />
        <DistrictCard form={form} />
        <ButtonCard buttonType={FormItemName.PRIMARY_BUTTON} form={form} />
        <ButtonCard buttonType={FormItemName.SECONDARY_BUTTON} form={form} />
        <ActionButtons disabledSaveButton={isLoading} />
      </Form>
      <ProductModals messageApi={messageApi} />
    </>
  );
};
