import { TableSortOrder } from '../utils/constants';
import { PRODUCTS_COLUMNS } from '../../modules/products/helpers/consts';
import { UploadMediaResponseT } from './media';
import { StatusLevelT } from './statusLevel';
import { ProductCategoryT } from './entities';
import { PointsOperator } from '../../modules/productConfigurator/types';
import { BadgeT } from './badge';

export enum Visibility {
  ALWAYS = 'ALWAYS',
  ONLY = 'VISIBLE_FOR_CUSTOMER',
  NEVER = 'NEVER',
}

export enum ScoringRules {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum CoverageTypeT {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

export type RecurringPointsT = {
  points: number;
  coverageAmount: number;
  operator: PointsOperator;
};

export type PriceT = {
  currency?: string;
  amount?: string;
  priceDescription?: string;
};

export enum Operation {
  FORM = 'form',
  IFRAME = 'iframe',
  REDIRECT = 'redirect',
}

export type ProductConfiguratorButtonT = {
  isButtonEnabled?: boolean;
  text?: string;
  link?: string;
  form?: unknown; // TODO add correct type when it is ready
  operation?: Operation;
};

export type ProductT = {
  id: number;
  name: string;
  activated: boolean;
  countUserProducts?: null | number;
  category: ProductCategoryT;
  projectId: number;
  categoryId: number;
  productId: string;
  scoringRanges: ScoringRules;
  recurringPoints?: RecurringPointsT[];
  points: number;
  price?: PriceT;
  priority: number;
  statusComment?: string;
  description?: string;
  subline?: string;
  email?: string;
  link?: string;
  visibility: Visibility;
  additionalInfo: string; // type AdditionalInfoT after parsing
  demandAreaPriority?: { number: number };
  categoryPriority?: { number: number };
  isWishlist?: boolean;
  reason?: StatusLevelT | null;
  irrelevantSurveyReason?: null | string;
  demandAreaId?: number;
  countSpecialProducts?: number;
  finderScore?: number;
  badgeId?: number;
  associatedProduct?: number;
  recommended?: boolean;
  special?: boolean;
  badge?: BadgeT;
  ageRange?: string; // type AgeRangeT after parsing
  region?: string; // type RegionT after parsing
  district?: string; // type DistrictT after parsing
};

export type GetProductsPayloadT = {
  page?: number;
  size?: number;
  sort?: string;
  includeIds?: number[];
  tenantId?: string;
  arrangedId?: PRODUCTS_COLUMNS;
  sortOrder?: TableSortOrder;
  sortFieldName?: string;
  withSorting?: boolean;
  filterFieldName?: string;
  filter?: string[] | string;
  projectId?: number;
};

export type CreateUpdateProductPayloadT = Omit<ProductT, 'category' | 'id'> & {
  id?: number;
};

export type AdditionalInfoT = {
  picture: UploadMediaResponseT;
  bulletPoints: string[];
  primaryButton: ProductConfiguratorButtonT;
  secondaryButton: ProductConfiguratorButtonT;
};

export type ProductRecommendationT = {
  id: number;
  products: number[];
  position: number;
  priority: ProductRecommendationPriorities;
};

export enum ProductRecommendationPriorities {
  'HighestToLowestProductPoints' = 'Highest to lowest product points',
  'LowestToHighestProductPoints' = 'Lowest to highest product points',
  'HighestToLowestPriority' = 'Highest to lowest priority',
  'LowestToHighestPriority' = 'Lowest to highest priority',
}
