import { Dispatch, FC, useState } from 'react';
import { Button } from 'antd';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ReactComponent as Letter } from './../../../../assets/icons/letter.svg';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserProductCard } from '../../../endUserProduct/components/EndUserProductCard';
import { SectionName } from '../../../../lib/models/Section';
import { ProductT } from '../../../../lib/types/product';
import { EndUserProductModal } from '../../../endUserProduct/components/EndUserProductModal';
import { findNextStatusLevel } from '../../../endUserProductRecommendation/helpers';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const translation = 'endUser.wishlist.productDetails';

type Props = {
  selectedProduct: ProductT | null;
  setIsContactModalOpen: Dispatch<boolean>;
  setSelectedProduct: Dispatch<ProductT | null>;
};

export const EndUserWishlistProductDetails: FC<Props> = ({
  selectedProduct,
  setIsContactModalOpen,
  setSelectedProduct,
}) => {
  const { brandKit, allProducts, wishlistProducts, demandArea, sections, isGuestMode, statusLevels } =
    useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const productSectionContent = sections?.[SectionName.PRODUCT_DETAILS];

  const userPoints = useCalculatePoints(allProducts);

  const { isMobile } = useContainerQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const onModalClose = () => {
    sendDataToAnalytics(
      'Wishlist product details section',
      'Close product details',
      `Product name: ${selectedProduct?.name}, Product id: ${selectedProduct?.productId}`,
    );

    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleOpenContactModal = (product: ProductT) => {
    sendDataToAnalytics(
      'Wishlist product details section',
      'Open product contact form',
      `Product name: ${product.name}, Product id: ${product.productId}`,
    );

    setIsContactModalOpen(true);
    setSelectedProduct(product);
  };

  const onSelectProduct = (product: ProductT) => {
    setSelectedProduct(product);

    sendDataToAnalytics(
      'Wishlist product details section',
      'Open product details',
      `Product name: ${product.name}, Product id: ${product.productId}`,
    );

    onModalOpen();
  };

  const wishlistProductWithReason = wishlistProducts.map((product) => ({
    ...product,
    reason: findNextStatusLevel(statusLevels, userPoints, product),
  }));

  const wishlistProductSection = {
    arePointsShown: true,
    isPriceShown: true,
    pointsIcon: demandArea?.project.pointsIcon,
    products: wishlistProductWithReason,
    brandKit,
    bullet: productSectionContent?.bullet,
    onSelectProduct: isMobile ? onSelectProduct : undefined,
    onOpenModal: handleOpenContactModal,
    isWishlistPage: true,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionHeader}>
        <EndUserTypography className={styles.title} type="headlineFourth" component="Title">
          {t(`${translation}.title`)} ({wishlistProducts?.length || 0})
        </EndUserTypography>
        <div>
          {isMobile && wishlistProducts?.length ? (
            <Button
              type="link"
              className={styles.headerButton}
              icon={<Letter width={16} height={16} />}
              onClick={onOpenContactModal}
            />
          ) : wishlistProducts?.length ? (
            <Button
              type="link"
              className={styles.headerButton}
              icon={<Letter />}
              onClick={onOpenContactModal}
            >
              <EndUserTypography type="body" component="Link" level={3}>
                {t(`${translation}.subline`)}
              </EndUserTypography>
            </Button>
          ) : null}
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {wishlistProductSection.products.map((product) => {
          return (
            <EndUserProductCard
              isIrrelevant={product.irrelevantSurveyReason}
              isInWishlist={true}
              product={product}
              key={product.id}
              isGuestMode={isGuestMode}
              {...wishlistProductSection}
            />
          );
        })}
      </div>

      <EndUserProductModal
        selectedProduct={selectedProduct}
        isModalOpen={isModalOpen}
        close={onModalClose}
        {...wishlistProductSection}
      />
    </div>
  );
};
