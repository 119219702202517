import { FC } from 'react';
import { ButtonFormType, FormItemName } from '../../../../lib/models/Form';
import { Card, Form, FormInstance, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormSection } from '../../../../components/FormSection';
import { FormItem } from '../../../../components/FormItem';
import { AppSelect } from '../../../../components/AppSelect';
import { Operation } from '../../../../lib/types/product';

import styles from './index.module.scss';

const button = 'pages.products.productConfigurator.formItems.button';

type Props = {
  buttonType: FormItemName.PRIMARY_BUTTON | FormItemName.SECONDARY_BUTTON;
  form: FormInstance;
};

export const ButtonCard: FC<Props> = ({ buttonType, form }) => {
  const { t } = useTranslation();

  const isButtonEnabled = Form.useWatch([buttonType, FormItemName.IS_BUTTON_ENABLED], form);
  const operation = Form.useWatch([buttonType, FormItemName.OPERATION], form);

  const options = [
    { value: Operation.IFRAME, title: t(`${button}.optionFrame`) },
    { value: Operation.FORM, title: t(`${button}.optionForm`) },
  ];

  const formOptions = [
    {
      value: ButtonFormType.PRODUCT_CONTACT,
      title: t(`${button}.productContact`),
    },
    {
      value: ButtonFormType.COVERAGE_INDICATION,
      title: t(`${button}.coverageIndication`),
    },
  ];

  return (
    <Card className={styles.root}>
      <div className={styles.titleWrapper}>
        <FormItem
          hasFeedback
          className={styles.formItem}
          name={[buttonType, FormItemName.IS_BUTTON_ENABLED]}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch className={styles.switch} size="small" />
        </FormItem>
        <FormItemTitle>{t(`${button}.${buttonType}`)}</FormItemTitle>
      </div>
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [buttonType, FormItemName.TEXT],
        }}
        textFieldProps={{
          placeholder: t(`${button}.label`),
          disabled: !isButtonEnabled,
        }}
      />

      <FormItem hasFeedback name={[buttonType, FormItemName.OPERATION]} initialValue={options[0]}>
        <AppSelect disabled={!isButtonEnabled} options={options} label={t(`${button}.operation`)} />
      </FormItem>

      {operation?.value === Operation.FORM ? (
        <FormItem hasFeedback name={[buttonType, FormItemName.FORM]}>
          <AppSelect disabled={!isButtonEnabled} label={t(`${button}.form`)} options={formOptions} />
        </FormItem>
      ) : (
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [buttonType, FormItemName.LINK],
          }}
          textFieldProps={{
            placeholder: t(`${button}.link`),
            disabled: !isButtonEnabled,
          }}
        />
      )}
    </Card>
  );
};
