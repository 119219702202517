import { FC } from 'react';
import { Modal } from 'antd';
import { TrackerSettingsForm } from '../../forms/TrackerSettingsForm.tsx';
import styles from './index.module.scss';

type Props = {
  isModalOpen: boolean;
  close: () => void;
};

export const TrackingSettingsModal: FC<Props> = ({ isModalOpen, close }) => {
  return (
    <Modal
      className={styles.modal}
      open={isModalOpen}
      onCancel={close}
      destroyOnClose={true}
      closeIcon={false}
      width={528}
      bodyStyle={{ margin: '-34px -24px -32px', padding: '24px 24px', maxHeight: 800, overflowY: 'auto' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <TrackerSettingsForm close={close} isModalOpen={isModalOpen} />
    </Modal>
  );
};
