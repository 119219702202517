import { FC, useMemo } from 'react';
import { CategoryOverviewSection } from '../../modules/endUserCategoryOverview/components/CategoryOverviewSection';
import { ProductRecommendationList } from '../../modules/endUserProductRecommendation/components/ProductRecommendationList';
import { StatusLevelSection } from '../../modules/endUserStatusLevel/components/StatusLevelSection';
import { BenefitsSection } from '../../modules/endUserBenefits/components/BenefitsSection';
import { EndUserHouseModel } from '../../modules/endUserHouseModel/components/EndUserHouseModel';
import { useAppSelector } from '../../lib/store/hooks';
import { NewHouseModel } from '../../modules/endUserHouseModelNew/components/NewHouseModel';
import { EndUserDemandAreaTiles } from '../../modules/EndUserDemandAreaTiles';

export const EndUserHomePage: FC = () => {
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const applyNewDesign =
    tenantAndProject?.subDomain === 'demo-company' && tenantAndProject?.tenantId === 11289;

  const isDev = tenantAndProject?.domain === 'dev.hvm.impltech.online' && tenantAndProject?.id === 11486;

  const applyNewHouse =
    isDev || (tenantAndProject?.subDomain === 'energie-schwaben' && tenantAndProject?.tenantId === 11002);

  //hardcode for this tenants
  const node = useMemo(() => {
    if (tenantAndProject?.subDomain === 'energie-schwaben' || isDev) {
      return (
        <>
          {applyNewHouse ? <NewHouseModel /> : <EndUserHouseModel />}
          <ProductRecommendationList />
          <CategoryOverviewSection />
          <BenefitsSection />
        </>
      );
    } else if (applyNewDesign) {
      return (
        <>
          <EndUserDemandAreaTiles />
          <StatusLevelSection />
          <CategoryOverviewSection />
          <ProductRecommendationList />
          <BenefitsSection />
        </>
      );
    } else {
      return (
        <>
          <EndUserHouseModel />
          <StatusLevelSection />
          <CategoryOverviewSection />
          <ProductRecommendationList />
          <BenefitsSection />
        </>
      );
    }
  }, [applyNewDesign, applyNewHouse, isDev, tenantAndProject?.subDomain]);

  return node;
};
