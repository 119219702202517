import React, { CSSProperties, FC } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import './index.scss';
import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  styles?: CSSProperties;
  scrollbarContentClassName?: string;
  trackYId?: string;
  trackYClassName?: string;
  translateContentSizeYToHolder?: boolean;
  translateContentSizeXToHolder?: boolean;
  scrollerClassName?: string;
};

export const ScrollbarWrapper: FC<Props> = ({
  children,
  scrollbarContentClassName,
  translateContentSizeYToHolder,
  translateContentSizeXToHolder,
  trackYClassName,
  scrollerClassName,
  trackYId,
  styles = {
    width: '100vw',
    height: '100vh',
  },
}) => {
  return (
    <Scrollbar
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      translateContentSizeXToHolder={translateContentSizeXToHolder}
      style={styles}
      contentProps={{
        //eslint-disable-next-line
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className={scrollbarContentClassName} />;
        },
      }}
      wrapperProps={{
        //eslint-disable-next-line
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <div {...restProps} id={trackYId} ref={elementRef} className={cn('trackY', trackYClassName)} />
          );
        },
      }}
      scrollerProps={{
        //eslint-disable-next-line
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className={scrollerClassName} />;
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="tHuMbY" />;
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};
