import { Button } from 'antd';
import { useState } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserBoughtProductModal } from '../../EndUserBoughtProductModal';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { AdditionalInfoT, ProductT } from '../../../../lib/types/product';
import { calculatePointsForProduct, getBoughtProducts } from '../../../../lib/utils/end-user-helpers';
import { parseJSON } from '../../../../lib/utils/helpers';
import { IRRELEVANT_REASONS } from '../../../../components/EndUserIrrelevantSurveyModal';
import { IconComponent } from '../../../../components/IconComponent';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const translation = 'endUser.boughtProducts';

export const EndUserBoughtProducts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const { isMobile } = useContainerQuery();
  const [selectedProduct, setSelectedProduct] = useState<ProductT | null>(null);

  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const isEnergieSchwaben = tenantAndProject?.subDomain === 'energie-schwaben';

  const onClickOpenModal = (product: ProductT) => {
    sendDataToAnalytics(
      'Bought products',
      `Open product, Product name: ${product.name}, Product id: ${product.id}`,
    );

    setSelectedProduct(product);

    setIsModalOpen(true);
  };

  const onModalClose = () => {
    sendDataToAnalytics(
      'Bought products',
      `Close product, Product name: ${selectedProduct?.name}, Product id: ${selectedProduct?.id}`,
    );
    setIsModalOpen(false);
  };
  const { demandArea, brandKit, allProducts, invisibleCategoryProducts } = useAppSelector(
    ({ endUser }) => endUser,
  );

  const purchasedProducts = getBoughtProducts(allProducts);
  const purchasedInvisibleProducts = getBoughtProducts(invisibleCategoryProducts);

  const allPurchasedProducts = [...purchasedProducts, ...purchasedInvisibleProducts].filter(
    (product) => product.irrelevantSurveyReason !== IRRELEVANT_REASONS.SUPPLIED_BY_THE_CLIENT.value,
  );

  const totalPoints = useCalculatePoints(allPurchasedProducts);

  return (
    <>
      <div className={styles.wrapper}>
        <EndUserTypography type="headlineSecond" className={styles.headerTitle} component="Title">
          {t(`${translation}.title`)} ({allPurchasedProducts?.length})
        </EndUserTypography>
        <div className={styles.cardWrapper}>
          {allPurchasedProducts?.map((product: ProductT, index) => {
            const additionalInfo: AdditionalInfoT = parseJSON(product?.additionalInfo);
            let totalProducts = product.countUserProducts || 0;

            if (product.countSpecialProducts) {
              totalProducts += product.countSpecialProducts;
            }

            const points = calculatePointsForProduct(product, totalProducts);

            const countUserProductsText = totalProducts > 1 ? ` (${totalProducts})` : '';
            return (
              <div key={index} className={styles.card}>
                <div className={styles.textBlock}>
                  <img className={styles.image} alt="product-img" src={additionalInfo.picture.s3Url} />
                  <div className={styles.textWrapper}>
                    <EndUserTypography className={styles.title} type={'headlineFourth'} component="Title">
                      {product.name} {countUserProductsText}
                    </EndUserTypography>
                    {isMobile ? (
                      <Button
                        onClick={() => onClickOpenModal(product)}
                        style={{ color: brandKit?.colors.primaryColor }}
                        className={styles.button}
                        type="link"
                      >
                        <EndUserTypography type="headlineEight">
                          {' '}
                          {t(`${translation}.details`)}
                        </EndUserTypography>
                      </Button>
                    ) : (
                      <>
                        {!isEnergieSchwaben && (
                          <EndUserTypography type="subText" component="Text">
                            {product.description}
                          </EndUserTypography>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.pointsContainer}>
                  <EndUserTypography type="headlineFifth" className={styles.pointsTitle} component="Title">
                    {points}

                    <IconComponent
                      className={styles.icon}
                      iconData={demandArea?.project.pointsIcon}
                      color={'currentColor'}
                    />
                  </EndUserTypography>
                </div>
              </div>
            );
          })}
          <div className={styles.summaryCard}>
            <EndUserTypography className={styles.title} type={'headlineFourth'} component="Text">
              {t(`${translation}.subline`)}
            </EndUserTypography>
            <EndUserTypography type={'headlineFourth'} component="Text" className={styles.pointHeadline}>
              {totalPoints}{' '}
              <IconComponent
                className={styles.summaryIcon}
                iconData={demandArea?.project.pointsIcon}
                color={'currentColor'}
              />
            </EndUserTypography>
          </div>
        </div>
      </div>
      <EndUserBoughtProductModal
        isModalOpen={isModalOpen}
        close={onModalClose}
        selectedProduct={selectedProduct}
        isEnergieSchwaben={isEnergieSchwaben}
      />
    </>
  );
};
