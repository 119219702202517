import { FC } from 'react';
import { WishlistPageT } from '../../../../lib/types/sections';
import { DemandArea } from '../../../../lib/types/endUser';
import { useNavigate } from 'react-router-dom';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserSquareTilesMobile } from '../../../EndUserDemandAreaTiles/components/EndUserSquareTilesMobile';
import { useAppSelector } from '../../../../lib/store/hooks';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

type Props = {
  content?: WishlistPageT;
  demandAreas: DemandArea[];
};
export const EndUserWishlistTilesMobile: FC<Props> = ({ content, demandAreas }) => {
  const navigate = useNavigate();
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;
  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Wishlist house model',
      `Open demand area`,
      `Demand Area Id: ${areaId}, Demand Area name: ${name}`,
    );

    const path = isFrenchTenant ? ROUTER_PATHS.houseModelFr : ROUTER_PATHS.houseModel;

    navigate(`/${path}/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundSize: 'cover',
        background: `url(${content?.backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
      }}
    >
      <div className={styles.overlay} />
      <div className={styles.headlineBlock}>
        <EndUserTypography type="headlineSecond" component="Title" style={{ marginBottom: '8px' }}>
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography
          className={styles.subTitle}
          type="headlineSixth"
          component="Title"
          style={{ margin: 0 }}
        >
          {content?.subline}
        </EndUserTypography>
      </div>

      <div className={styles.tilesContainer}>
        <EndUserSquareTilesMobile
          demandAreas={demandAreas}
          handleAreaClick={handleAreaClick}
          handleKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};
