import { FC, useMemo, useState } from 'react';
import { EndUserModal } from '../../../../components/EndUserModal';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Form } from 'antd';
import { EndUserContactForm } from '../ContactForm';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { ProductT } from '../../../../lib/types/product';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useSendContactFormMutation } from '../../../../lib/store/api/endUser';
import { SuccessStateModal } from '../SuccessStateModal';
import { ButtonFormType, FormItemName } from '../../../../lib/models/Form';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { SectionName } from '../../../../lib/models/Section';
import { ContactFormBody, FormTypeProduct } from '../../../../lib/types/endUser';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { parseJSON } from '../../../../lib/utils/helpers';

const contactForm = 'endUser.contactForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  products: ProductT[] | null;
  isWishlist?: boolean;
  fromFooter?: boolean;
};

export const EndUserContactFormModal: FC<Props> = ({
  products: productsList,
  isOpen,
  onClose,
  isWishlist,
  fromFooter,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { tenantAndProject, isGuestMode, sections } = useAppSelector(({ endUser }) => endUser);
  const [sendContactForm, { isLoading }] = useSendContactFormMutation();
  const userEmail = form.getFieldValue(FormItemName.EMAIL);
  const currentSectionContent = sections?.[SectionName.CONTACT_FORM];
  const [isChecked, setIsChecked] = useState(false);

  useResetFormOnCloseModal({ form, isOpen });

  const additionalInfo = useMemo(() => {
    if (isWishlist || !productsList?.length) {
      return null;
    }
    return parseJSON(productsList[0].additionalInfo);
  }, [isWishlist, productsList]);

  const isCoverageIndicationForm = useMemo(() => {
    if (additionalInfo) {
      return (
        additionalInfo?.primaryButton[FormItemName.FORM]?.value === ButtonFormType.COVERAGE_INDICATION ||
        additionalInfo?.secondaryButton[FormItemName.FORM]?.value === ButtonFormType.COVERAGE_INDICATION
      );
    }
  }, [additionalInfo]);

  const buttonFormType = useMemo(() => {
    if (isCoverageIndicationForm) {
      return FormTypeProduct.COVERAGE_INDICATION;
    } else if (fromFooter) {
      return FormTypeProduct.FOOTER_CONTACT;
    }
    return FormTypeProduct.PRODUCT_CONTACT;
  }, [fromFooter, isCoverageIndicationForm]);

  const toggleCheckbox = () => {
    setIsChecked((prevState) => !prevState);
  };

  const handleSubmit = async (values: Omit<ContactFormBody, 'products'>) => {
    const tenantId = tenantAndProject?.tenantId;
    const projectId = tenantAndProject?.projectId;

    const products = productsList?.map((item) => item.id);

    try {
      await sendContactForm({
        isGuestMode,
        tenantId: String(tenantId),
        projectId: Number(projectId),
        body: {
          ...values,
          buttonFormType: buttonFormType,
          products,
        },
      }).unwrap();

      setIsSuccessModalOpen(true);

      sendDataToAnalytics('Contact form submit,', `${JSON.stringify(values)}`);
      sendDataToAnalytics(
        'Interesse für vorgemerkte Produkte',
        'E-Mail senden',
        'User möchte gemerkte Produkte per E-Mail erhalten',
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSubmit = () => {
    form.submit();
  };

  const handleCloseModals = () => {
    sendDataToAnalytics('Contact form success');

    onClose();
    setIsSuccessModalOpen(false);
  };

  const subtitle = useMemo(() => {
    if (isCoverageIndicationForm) {
      return currentSectionContent?.subtitle2;
    } else if (fromFooter) {
      return currentSectionContent?.subtitle3;
    } else {
      return currentSectionContent?.subtitle;
    }
  }, [currentSectionContent, fromFooter, isCoverageIndicationForm]);

  const text = useMemo(() => {
    if (isCoverageIndicationForm) {
      return currentSectionContent?.text2;
    } else if (fromFooter) {
      return currentSectionContent?.text3;
    } else {
      return currentSectionContent?.text;
    }
  }, [fromFooter, isCoverageIndicationForm, currentSectionContent]);

  return !isSuccessModalOpen ? (
    <EndUserModal
      open={isOpen}
      titleProps={{ title: currentSectionContent?.title, subTitle: subtitle }}
      cancelButtonProps={{ onClick: onClose }}
      actionButtonProps={{
        onClick: handleClickSubmit,
        loading: isLoading,
        disabled: isCoverageIndicationForm && !isChecked,
      }}
      cancelText={t(`${contactForm}.cancelText`)}
      okText={t(`${contactForm}.okText`)}
    >
      <EndUserTypography type="body" component="Text" className={styles.text}>
        {text}
      </EndUserTypography>
      <EndUserContactForm
        form={form}
        onFinish={handleSubmit}
        isCoverageIndicationForm={isCoverageIndicationForm}
        isChecked={isChecked}
        toggleCheckbox={toggleCheckbox}
        fromFooter={fromFooter}
      />
    </EndUserModal>
  ) : (
    <SuccessStateModal isOpen={isSuccessModalOpen} onClose={handleCloseModals} userEmail={userEmail} />
  );
};
