import { FC } from 'react';
import { Card as AppCard, Form, message, MessageArgsProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { isFetchBaseQueryError, secToMs } from '../../../../lib/utils/helpers';
import { Button } from '../../../../components/Button';
import { AppTitle } from '../AppTitle';
import { RepeatPasswordFormItem } from '../RepeatPasswordFormItem';
import { SetupPasswordFormItem } from '../SetupPasswordFormItem';
import { FormItemName } from '../../../../lib/models/Form';

import styles from './styles.module.scss';
import { useResetPasswordMutation } from '../../../../lib/store/api/auth';
import { useAppSelector } from '../../../../lib/store/hooks';
import { END_USER_FRENCH_TENANT_MODEL_NAME } from '../../../../lib/utils/constants';

const setUpPassTrans = 'pages.setUpPassword';
const badLinkErrorMessage = `${setUpPassTrans}.badLinkErrorMessage`;
const successMessage = `${setUpPassTrans}.successMessage`;
const tittle = `${setUpPassTrans}.tittle`;

const resetPasswordTrans = 'pages.resetPassword';
const actionButton = `${resetPasswordTrans}.actionButton`;
const newPassword = `${resetPasswordTrans}.form.newPassword`;
const repeatNewPassword = `${resetPasswordTrans}.form.repeatNewPassword`;
const pageErrors = `${resetPasswordTrans}.errors.pageErrors`;

const MESSAGE_SHOWING_TIME = 4; // seconds

export const PasswordResetCard: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { token } = useParams();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const isFrenchTenant = demandArea?.project?.name === END_USER_FRENCH_TENANT_MODEL_NAME;

  const redirectToLogin = () => {
    const path = isFrenchTenant ? ROUTER_PATHS.logInFr : ROUTER_PATHS.logIn;

    navigate(path, { replace: true });
  };

  const isBothFieldsTouched =
    form.isFieldTouched(FormItemName.PASSWORD) && form.isFieldTouched(FormItemName.REPEAT_PASSWORD);

  const errors = form.getFieldsError();

  const isSaveButtonAvailable =
    isBothFieldsTouched && errors[0].errors.length === 0 && errors[1].errors.length === 0;

  const onFinish = async (formValues: { password: string }) => {
    const messageConfig = {} as MessageArgsProps;
    const result = await resetPassword({
      key: token ?? '',
      newPassword: formValues.password,
    });

    if ('error' in result) {
      const err = result.error;
      let msg = '';

      if (isFetchBaseQueryError(err)) {
        const errorString = JSON.stringify(err.data);
        const errorObject = JSON.parse(errorString);

        if (err.status === 500) {
          msg = t(badLinkErrorMessage);
          setTimeout(redirectToLogin, secToMs({ sec: MESSAGE_SHOWING_TIME }));
        } else if (err.status === 400) {
          msg =
            errorObject.fieldErrors?.[0]?.message ||
            errorObject.message ||
            errorObject.detail ||
            t(`${pageErrors}.unknownError`);
        }
      }

      messageConfig.content = msg;
      messageConfig.type = 'error';
    } else {
      messageConfig.content = t(successMessage);
      messageConfig.type = 'success';
      setTimeout(redirectToLogin, secToMs({ sec: MESSAGE_SHOWING_TIME }));
    }

    messageApi.open({
      duration: MESSAGE_SHOWING_TIME,
      ...messageConfig,
    } as MessageArgsProps);
  };

  return (
    <AppCard bodyStyle={{ padding: 0 }} className={styles.wrapper}>
      <AppTitle className={styles.title}>
        <Trans
          className={styles.title}
          i18nKey={tittle}
          components={{
            regularText: <span className={styles.regularTitle} />,
          }}
        />
      </AppTitle>
      {contextHolder}

      <Form form={form} onFinish={onFinish}>
        {
          (() => {
            return (
              <div>
                <SetupPasswordFormItem
                  label={newPassword}
                  errors={form.getFieldError(FormItemName.PASSWORD)}
                  isTouched={form.isFieldTouched(FormItemName.PASSWORD)}
                />
                <RepeatPasswordFormItem label={repeatNewPassword} />

                <Button
                  htmlType="submit"
                  disabled={!isSaveButtonAvailable}
                  loading={isLoading}
                  style={{ marginTop: '35px' }}
                >
                  {t(actionButton)}
                </Button>
              </div>
            );
          }) as any
        }
      </Form>
    </AppCard>
  );
};
