import styles from './styles.module.scss';
import { useLoginSection } from '../../../../app/layouts/EndUserFlowLayout/EndUserAuthLayout';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserHouse } from '../../../endUserHouseModel/components/EndUserHouse';
import cn from 'classnames';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';
import { useMemo } from 'react';

export const EndUserLoginHouseModel = () => {
  const { loginSection } = useLoginSection();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const { windowHeight } = useWindowResize();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);

  const houseProps = {
    demandAreas: slicedDemandAreas,
    buttonColor: loginSection?.buttonColor,
    buttonText: loginSection?.buttonText,
  };

  const houseWrapperStyleForSmallDesktops = useMemo(
    () =>
      windowHeight <= 900
        ? {
            transform: 'scale(0.7)',
            marginTop: -110,
            marginLeft: 20,
            transformOrigin: 'left',
            width: 455,
          }
        : {},
    [windowHeight],
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <EndUserTypography className={styles.title} component="Title" level={1} type="headlineFirst">
            {loginSection?.headline1}
          </EndUserTypography>
          <EndUserTypography className={styles.subTitle} component="Title" level={3} type="headlineThird">
            {loginSection?.headline2}
          </EndUserTypography>
        </div>

        <div className={cn(styles.houseModelWrapper)}>
          <div style={houseWrapperStyleForSmallDesktops}>
            <EndUserHouse {...houseProps} />
          </div>
        </div>
      </div>
    </div>
  );
};
