import React, { FC, useEffect } from 'react';
import styles from './index.module.scss';
import { IconButton } from '../../../../../../components/IconButton';
import { Form } from 'antd';
import { EndUserTypography } from '../../../../../../components/EndUserTypography';
import { FormItem } from '../../../../../../components/FormItem';
import { FormItemName } from '../../../../../../lib/models/Form';
import { AppCheckbox } from '../../../../../../components/AppCheckox';
import { EndUserButton } from '../../../../../../components/EndUserButton';
import { useAppDispatch, useAppSelector } from '../../../../../../lib/store/hooks';
import {
  useGetEndUserDataQuery,
  useUpdateEndUserAccountMutation,
} from '../../../../../../lib/store/api/auth';
import { useResetFormOnCloseModal } from '../../../../../../lib/hooks/useResetFormOnCloseModal';
import { ReactComponent as CrossIcon } from './../../../../../../assets/icons/CrossIcon.svg';
import { setMatomoTrackingForGuest } from '../../../../../../lib/store/slices/endUserSlice';
import { sendDataToAnalytics } from '../../../../../../lib/utils/sendDataToAnalytics';
import { EndUserAuthBottomButtons } from '../../../../../../components/EndUserAuthBottomButtons';
import { SectionName } from '../../../../../../lib/models/Section';
import { useGetCheckboxAgreementText } from '../../../../../../modules/endUserSignUp/hooks/useGetCheckboxAgreementText';
import { useTranslation } from 'react-i18next';

const translation = 'endUser.trackingInfo';
const shared = 'endUser.shared';

type FormPayloadT = {
  agreeExpandUserExperience: boolean;
  agreeUseName: boolean;
  agreeInformByEmail: boolean;
  agreeInformByPhone: boolean;
};

type Props = {
  isModalOpen: boolean;
  close: () => void;
};

export const TrackerSettingsForm: FC<Props> = ({ isModalOpen, close }) => {
  const { demandArea, tenantAndProject, isGuestMode, matomoEnabledByUserInGuestMode, sections } =
    useAppSelector(({ endUser }) => endUser);
  const [updateUserAccount] = useUpdateEndUserAccountMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dataProtectionLink = demandArea?.tenant.dataProtection || '/';
  const agbLink = demandArea?.tenant.agb || '/';
  const currentSectionContent = sections?.[SectionName.AGREEMENTS];

  const isUpdatedAgreements = currentSectionContent?.isUpdatedAgreement;
  const renderPostCheckbox = currentSectionContent && currentSectionContent?.byPost?.length > 0;

  const { getCheckboxText } = useGetCheckboxAgreementText({
    agbLink,
    dataProtectionLink,
    clientName: demandArea?.tenant?.clientName || '',
  });

  const { data, refetch } = useGetEndUserDataQuery(
    { tenantId: String(tenantAndProject?.tenantId) },
    { skip: isGuestMode, refetchOnMountOrArgChange: true },
  );

  const [form] = Form.useForm();

  useResetFormOnCloseModal({ form, isOpen: isModalOpen });

  const onFinish = async (formValues: FormPayloadT) => {
    if (isGuestMode) {
      dispatch(setMatomoTrackingForGuest({ trackingEnabled: formValues.agreeUseName }));

      if (window._paq) {
        if (formValues.agreeUseName) {
          window._paq.push(['rememberConsentGiven']);
        } else {
          window._paq.push(['forgetConsentGiven']);
        }
      }
    } else {
      await handleSubmit(formValues);
    }

    close();
  };

  const handleSubmit = async (formValues: FormPayloadT) => {
    try {
      await updateUserAccount({ ...formValues, tenantId: tenantAndProject?.tenantId }).unwrap();
      refetch();
    } catch (err) {
      // console.log(err);
    } finally {
      if (window._paq) {
        sendDataToAnalytics(
          `Tracker settings, save configuration, Agree use Matomo: ${formValues.agreeUseName}, Agree expand user experience: ${formValues.agreeExpandUserExperience}`,
        );

        if (formValues.agreeUseName) {
          window._paq.push(['rememberConsentGiven']);
        } else {
          window._paq.push(['forgetConsentGiven']);
        }
      }
    }
  };

  const handleChangeMatomo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    sendDataToAnalytics(
      `Tracker settings, 'Checkbox', ${checked ? 'Agree use Matomo' : 'Disagree use Matomo'}`,
    );
  };

  const handleChangeExpandUserExperience = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    sendDataToAnalytics(
      `Tracker settings, 'Checkbox', ${
        checked ? 'Agree expand user experience' : 'Disagree expand user experience'
      }`,
    );
  };

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (data) {
      if (isUpdatedAgreements) {
        form.setFieldsValue({
          [FormItemName.AGREE_USE_NAME]: Boolean(data.agreeUseName),
          [FormItemName.AGREE_INFORM_BY_EMAIL]: Boolean(data.agreeInformByEmail),
          [FormItemName.AGREE_INFORM_BY_PHONE]: Boolean(data.agreeInformByPhone),
          [FormItemName.AGREE_INFORM_BY_POST]: renderPostCheckbox ? Boolean(data.agreeInformByPost) : false,
        });
      } else {
        form.setFieldsValue({
          [FormItemName.AGREE_USE_NAME]: Boolean(data.agreeUseName),
          [FormItemName.AGREE_INFORM_BY_EMAIL]: Boolean(data.agreeInformByEmail),
        });
      }
    }

    if (isGuestMode) {
      form.setFieldsValue({
        [FormItemName.AGREE_USE_NAME]: matomoEnabledByUserInGuestMode,
      });
    }
  }, [data, form, isGuestMode, isUpdatedAgreements, matomoEnabledByUserInGuestMode, renderPostCheckbox]);

  return (
    <>
      <div className={styles.container}>
        <IconButton className={styles.icon} onClick={close} icon={<CrossIcon />} />
        <EndUserTypography className={styles.title} type="headlineThird">
          {t(`${translation}.title`)}
        </EndUserTypography>
        <Form
          initialValues={{ [FormItemName.READ_TERMS_AND_PRIVACY_POLICY]: true }}
          form={form}
          onFinish={onFinish}
          className={styles.form}
        >
          <FormItem
            name={FormItemName.AGREE_USE_NAME}
            hasFeedback={true}
            className={styles.checkbox}
            valuePropName="checked"
            rules={[
              {
                required: false,
                message: '',
                type: 'boolean',
                transform: (value: any) => value || undefined,
              },
            ]}
          >
            <AppCheckbox
              onChange={handleChangeMatomo}
              className={styles.checkboxContent}
              label={
                <EndUserTypography type="headlineSeventh" component="Text">
                  {getCheckboxText(currentSectionContent?.agreeUseName)}
                </EndUserTypography>
              }
            />
          </FormItem>
          {isUpdatedAgreements && !isGuestMode && (
            <>
              <EndUserTypography className={styles.checkboxText} type="headlineSeventh" component="Text">
                {getCheckboxText(currentSectionContent?.byPhone1)}
              </EndUserTypography>
              <FormItem
                valuePropName="checked"
                name={FormItemName.AGREE_INFORM_BY_PHONE}
                hasFeedback={true}
                className={styles.checkbox}
                rules={[
                  {
                    required: false,
                    message: '',
                    type: 'boolean',
                    transform: (value: any) => value || undefined,
                  },
                ]}
              >
                <AppCheckbox
                  onChange={handleChangeExpandUserExperience}
                  className={styles.checkboxContent}
                  label={
                    <EndUserTypography type="headlineSeventh" component="Text">
                      {getCheckboxText(currentSectionContent?.byPhone2)}
                    </EndUserTypography>
                  }
                />
              </FormItem>
              <FormItem
                valuePropName="checked"
                name={FormItemName.AGREE_INFORM_BY_EMAIL}
                hasFeedback={true}
                className={styles.checkbox}
                rules={[
                  {
                    required: false,
                    message: '',
                    type: 'boolean',
                    transform: (value: any) => value || undefined,
                  },
                ]}
              >
                <AppCheckbox
                  onChange={handleChangeExpandUserExperience}
                  className={styles.checkboxContent}
                  label={
                    <EndUserTypography type="headlineSeventh" component="Text">
                      {getCheckboxText(currentSectionContent?.byEmail2)}
                    </EndUserTypography>
                  }
                />
              </FormItem>
              {renderPostCheckbox && (
                <FormItem
                  valuePropName="checked"
                  name={FormItemName.AGREE_INFORM_BY_POST}
                  hasFeedback={true}
                  className={styles.checkbox}
                  rules={[
                    {
                      required: false,
                      message: '',
                      type: 'boolean',
                      transform: (value: any) => value || undefined,
                    },
                  ]}
                >
                  <AppCheckbox
                    className={styles.checkboxContent}
                    label={
                      <EndUserTypography type="headlineSeventh" component="Text">
                        per Brief
                      </EndUserTypography>
                    }
                  />
                </FormItem>
              )}

              <EndUserTypography className={styles.checkboxText} type="headlineSeventh" component="Text">
                {getCheckboxText(currentSectionContent?.byEmail)}
              </EndUserTypography>
            </>
          )}
          {!isGuestMode && !isUpdatedAgreements && (
            <FormItem
              valuePropName="checked"
              name={FormItemName.AGREE_INFORM_BY_EMAIL}
              hasFeedback={true}
              className={styles.checkbox}
              rules={[
                {
                  required: false,
                  message: '',
                  type: 'boolean',
                  transform: (value: any) => value || undefined,
                },
              ]}
            >
              <AppCheckbox
                onChange={handleChangeExpandUserExperience}
                className={styles.checkboxContent}
                label={
                  <EndUserTypography type="headlineSeventh" component="Text">
                    {getCheckboxText(currentSectionContent?.agreeExpandUserExperience)}
                  </EndUserTypography>
                }
              />
            </FormItem>
          )}

          <FormItem
            name={FormItemName.READ_TERMS_AND_PRIVACY_POLICY}
            hasFeedback={true}
            className={styles.checkbox}
            valuePropName="checked"
            rules={[
              {
                required: false,
                message: '',
                type: 'boolean',
                transform: (value: any) => value || undefined,
              },
            ]}
          >
            <AppCheckbox
              isDisabled={true}
              className={styles.checkboxContent}
              label={
                <EndUserTypography type="headlineSeventh" component="Text">
                  {getCheckboxText(currentSectionContent?.readTermsAndPrivacyPolicy)}
                </EndUserTypography>
              }
            />
          </FormItem>

          <EndUserTypography className={styles.subText} type="subText" component="Text">
            * {t(`${shared}.mandatory`)}
          </EndUserTypography>

          <EndUserAuthBottomButtons />
        </Form>
        <div className={styles.bottomButtons}>
          <EndUserButton onClick={close} type="secondary">
            {t(`${shared}.cancel`)}
          </EndUserButton>
          <EndUserButton onClick={onSubmit} type="primary">
            {t(`${shared}.save`)}
          </EndUserButton>
        </div>
      </div>
    </>
  );
};
